/** @jsxImportSource theme-ui */
import { useState, useEffect } from "react";

import { GameState } from "../selector/gameSelector";
import { usePrevious } from "../../hooks/usePrevious";
import { AudioComponent } from "../../components/Audio";

type Props = {
  gamePhase: GameState;
};

export const NarrationComponent: React.FC<Props> = ({ gamePhase }) => {
  const [transitioning, setTransitioning] = useState(false);

  const previousPhase = usePrevious(gamePhase);
  useEffect(() => {
    if (previousPhase?.phase !== gamePhase.phase) {
      setTransitioning(true);
      setTimeout(() => {
        setTransitioning(false);
      }, 100);
    }
  }, [previousPhase, gamePhase, setTransitioning]);

  return (
    <div>
      {gamePhase.narration && !transitioning && <AudioComponent url={gamePhase.narration} />}
    </div>
  );
};
