/** @jsxImportSource theme-ui */

import { useState } from "react";

import { CountdownComponent } from "../../components/Countdown";
import { LoadingComponent } from "../../components/LoadingSpinner";

export const LoadingPage = ({ phaseDuration }: { phaseDuration?: number }) => {
  const [renderStart] = useState(Date.now());
  const duration = (phaseDuration || 0) - (Date.now() - renderStart);

  return (
    <div>
      {phaseDuration && <CountdownComponent millisecondDuration={duration} />}
      <LoadingComponent />
      <p>Other players are writing. Give them a bit of time to finish.</p>
    </div>
  );
};
