/** @jsxImportSource theme-ui */
import { ThemeProvider } from "theme-ui";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";

import amplitude from "amplitude-js";
import { AmplitudeProvider } from "@amplitude/react-amplitude";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { cache } from "emotion";
import { CacheProvider } from "@emotion/core";

import { AudioContext } from "./context/audioContext";
import { useLocalStorage } from "./hooks/useLocalStorage";

import { AMPLITUDE_KEY, AUTH_USERNAME, STRIPE_KEY } from "./config";
import { configureStore } from "./store";
import theme from "./theme";
import { HomePage } from "./pages/home/home";

import { Signup } from "./pages/auth/signup";
import { ConfirmEmail } from "./pages/auth/confirmEmail";
import { Login } from "./pages/auth/login";
import { ForgotPassword } from "./pages/auth/forgotPassword";
import { PasswordReset } from "./pages/auth/passwordReset";
import { StartPage } from "./pages/start/start";
import { JoinPage } from "./pages/join/join";
import { LobbyPage } from "./pages/lobby/lobby";
import { GamePage } from "./pages/game/game";
import { PlayPage } from "./pages/play/play";
import { AccountPage } from "./pages/account/account";
import { PrivateRoute } from "./PrivateRoute";
import { useEventListener } from "./hooks/useEventListener";
import { PrivacyPolicy } from "./pages/legal/privacy";
import { Terms } from "./pages/legal/terms";
import { ErrorFallback } from "./components/ErrorFallback";
import { NotFoundPage } from "./pages/404/404";
import { ShouldUpdate } from "./components/ShouldUpdate/update";

const { store } = configureStore();

const stripePromise = loadStripe(STRIPE_KEY);
const WindowAudioContext = window.AudioContext || (window as any).webkitAudioContext;
const globalAudioContext = new WindowAudioContext();

const App = () => {
  const [initialContext, setContext] = useLocalStorage("audioContext", {
    isMuted: false,
    volume: "50",
  });
  const [audioContext, setAudio] = useState(initialContext);

  // disable mobile zoom
  const [lastTouchend, setLastTouchend] = useState(0);
  useEventListener(
    "touchend",
    (event: any) => {
      const now = new Date().getTime();
      if (now - lastTouchend <= 250) {
        event.preventDefault();
      }
      setLastTouchend(now);
    },
    document
  );

  useEffect(() => {
    const amplitudeInstance = amplitude.getInstance();
    amplitudeInstance.setVersionName(`our-story-gif@${process.env.REACT_APP_VERSION}`);
    const username = localStorage?.getItem(AUTH_USERNAME);
    amplitudeInstance.setUserId(username);
  });

  return (
    <CacheProvider value={cache}>
      <Provider store={store}>
        <AmplitudeProvider amplitudeInstance={amplitude.getInstance()} apiKey={AMPLITUDE_KEY}>
          <ThemeProvider theme={theme}>
            <AudioContext.Provider
              value={{
                ...audioContext,
                context: globalAudioContext,
                setAudio: (context: any) => {
                  setAudio(context);
                  setContext(context);
                },
              }}
            >
              <Elements stripe={stripePromise}>
                <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
                  <BrowserRouter>
                    <React.Fragment>
                      <Switch>
                        <Route exact={true} path="/" component={HomePage} />
                        <Route exact={true} path="/start" component={StartPage} />
                        <Route exact={true} path="/join" component={JoinPage} />
                        <Route exact={true} path="/lobby" component={LobbyPage} />
                        <Route exact={true} path="/game" component={GamePage} />
                        <Route exact={true} path="/play/:id" component={PlayPage} />
                        <Route exact={true} path="/signup" component={Signup} />
                        <Route exact={true} path="/confirm/:username" component={ConfirmEmail} />
                        <Route exact={true} path="/login*" component={Login} />
                        <Route exact={true} path="/forgot-password" component={ForgotPassword} />
                        <Route
                          exact={true}
                          path="/forgot-password/:username"
                          component={PasswordReset}
                        />
                        <PrivateRoute exact={true} path="/account" component={AccountPage} />
                        <Route exact={true} path="/privacy" component={PrivacyPolicy} />
                        <Route exact={true} path="/terms" component={Terms} />
                        {/* Fallthrough */}
                        <Route component={NotFoundPage} />
                      </Switch>
                      <ShouldUpdate />
                    </React.Fragment>
                  </BrowserRouter>
                </Sentry.ErrorBoundary>
              </Elements>
            </AudioContext.Provider>
          </ThemeProvider>
        </AmplitudeProvider>
      </Provider>
    </CacheProvider>
  );
};

export default Sentry.withProfiler(App);
