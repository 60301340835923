import { ActionsObservable, combineEpics } from "redux-observable";
import { Observable, of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import { PAYMENT_API_BASE } from "../../config";
import { GET_SECRET, setSecretAction } from "./paymentActions";

export const getSecretEpic = (action$: ActionsObservable<any>): Observable<any> =>
  action$.ofType(GET_SECRET).pipe(
    switchMap((action) =>
      fetch(`${PAYMENT_API_BASE}/secret/${action.payload.username}`).then((result) => result.json())
    ),
    map((secretResponse) => setSecretAction(secretResponse)),
    catchError(() => of("error"))
  );

export default combineEpics(getSecretEpic);
