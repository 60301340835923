/** @jsxImportSource theme-ui */

import { Amplitude } from "@amplitude/react-amplitude";
import copy from "copy-to-clipboard";
import ReactTooltip from "react-tooltip";

import { Button } from "../../components/Button";

export const LobbyInviteSection = ({
  roomKey,
  hint,
}: {
  roomKey: string | null;
  hint: false | JSX.Element;
}) => {
  if (!roomKey) return null;
  return (
    <div sx={{ backgroundColor: "area" }}>
      {hint ? hint : <p sx={{ marginBottom: 0, paddingTop: 3 }}>Invite Friends</p>}

      <Amplitude>
        {({ logEvent }: any) => (
          <Button
            label="Invite"
            variation="small"
            onClick={() => {
              logEvent("click:join share link");
              copy(`${window.location.protocol}//${window.location.host}/join?code=${roomKey}`);
            }}
            data-tip="Link copied!"
          />
        )}
      </Amplitude>
      <ReactTooltip
        place="top"
        type="success"
        event="mouseup"
        eventOff="blur"
        effect="float"
        backgroundColor="#222222"
        afterShow={() => {
          setTimeout(() => {
            ReactTooltip.hide();
          }, 1000);
        }}
      />
      <p sx={{ paddingBottom: 3, marginTop: 0 }}>Code: {roomKey}</p>
    </div>
  );
};
