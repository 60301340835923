export const openerTemplates = [
  `"Welcome", said {name} as they {action} in {place}`,
  "{name} and {name} {action} at the sight of {place}",
  "{name} prepared {place} for guests that {action}",
  `{place} was quiet until {name} showed up and {action}`,
  `{name} and {name} {action} to {place}. Things were about to get real.`,

  // Holiday focus
  `{name} couldn't get into the holiday spirit. They {action} around {place}`,
];

export const midgameTemplates = [
  // action focus
  `{name} {action} as fast as they could to {place}`,
  `{name} {action} as quietly as possible to {place}`,
  `Having been trained, {name} {action} and made quick work of the foes from {place}`,
  `After they {action} {name} crept toward {place}`,
  "Thinking of {name}, {name} decided to act. They {action} at {place}",
  "Who would have guessed that {name} would {action} right in front of {name}?",

  // investigation focus
  `Looking around, {name} wondered if they should have {action} in {place}`,
  `{name} {action} over to {place} to figure out what all the fuss was about`,

  // interaction focus
  `"We had to", said {name} as they {action} toward {place}`,
  `"Watch out!" yelled {name} from {place} as enemies {action}`,
  `"Where is it?", yelled {name} as they {action} from {place} to {place}`,
  `"Give it to me {name}. I {action} before we got to {place}"`,
  `"Well... I {action}. I'm never going to do that again.", said {name} from a {place} bar"`,
  `{name} said "Oh no!". {name} had just {action}`,

  // describe focus
  `{name} had spent time training in {place} and {action}`,
  "Scowling, {name} continued. They {action} at the base of {place}",
  "Worried, {name} began and {action} from the spot on {place}",
  "After coming in, {name} nearly {action} all over {place}",
  `{name} gained a new perspective in {place} after they {action}`,

  // Holiday focus
  `Filled with hope, {name} traveled to {place} and {action}`,
  `"I don't think {action} counts as a gift", said {name}`,
];

export const endgameTemplates = [
  "With heavy hearts {name} and {name} continued. They {action} their way to {place}",
  "After such an amazing performance, {name} couldn't help it. They {action} near {place}",
  "With a mischievous wink, {name} {action}. Later they appeared at {place}",
  "{name} {action} to mark the occasion and remind everyone of their time in {place}",
  `"We all learned a lot today" said {name}. Everyone {action} from their view of {place}`,
  `The last everyone saw of {name} was when they {action} from {place}`,
  
  // Holiday focus
  `"What a great way to spend the holidays", said {name} as they saw everyone {action}`,
];
