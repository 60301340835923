/** @jsxImportSource theme-ui */
import { Themed, ThemeUICSSObject } from "theme-ui";
import { useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";

type Props = {
  millisecondDuration: number;
  modLength?: number;
  onTick?: (seconds: number) => void;
};

export const CountdownComponent = ({ millisecondDuration, modLength, onTick }: Props) => {
  const [startTime] = useState(Date.now());
  const [fifteenSecondWarning, setWarning] = useState(false);
  const text: ThemeUICSSObject = {
    color: "primary",
    paddingTop: "2px",
  };
  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }: CountdownRenderProps) => {
    const display = seconds + minutes * 60;
    if (completed) {
      return <Themed.h4 sx={text}>0</Themed.h4>;
    } else {
      return <Themed.h4 sx={text}>{modLength ? display % modLength : display}</Themed.h4>;
    }
  };

  return (
    <div sx={{ position: "relative" }}>
      <div
        sx={{
          position: "fixed",
          top: "20px",
          right: ["30px", "20%"],
          backgroundColor: fifteenSecondWarning ? "aside" : "area",
          borderRadius: "50%",
          height: ["40px", "40px", "50px"],
          width: ["40px", "40px", "50px"],
          textAlign: "center",
          zIndex: 20,
        }}
      >
        <Countdown
          date={startTime + millisecondDuration}
          renderer={renderer}
          onTick={(time) => {
            if (time.total < 15000) {
              setWarning(true);
            }
            if (onTick) {
              onTick(time.seconds + time.minutes * 60);
            }
          }}
        />
      </div>
    </div>
  );
};
