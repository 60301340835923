/** @jsxImportSource theme-ui */

type Props = {
  name: string;
  colorIndex: number;
  isLarge?: boolean;
};

export const PlayerMarker = ({ name, colorIndex, isLarge = false }: Props) => {
  const colors = ["#F75E50", "#8CA3CB", "#91C09E", "#C0A9C2", "#F0C73A"];
  const color = colors[colorIndex % colors.length];

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        height: isLarge ? "50px" : "35px",
        justifyItems: "center",
      }}
    >
      <span
        sx={{
          backgroundColor: color,
          color: "#302f2d",
          padding: isLarge ? 2 : 1,
          marginRight: -1,
          fontFamily: "Anton, sans-serif",
          fontSize: isLarge ? 3 : 2,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {name}
      </span>
      <div sx={{ height: "inherit" }}>
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 500 500" height="inherit">
          <path
            fill={color}
            d="M499.27,5.25l-306.83,250.1l306.81,239.39c1.69,2.07,0.28,5.26-2.34,5.26H3.07c-1.69,0-1.29-9.01-1.29-10.77
    l0,0L0,3.19C0,1.43,1.37,0,3.07,0l493.86,0C499.54,0,500.95,3.18,499.27,5.25z"
          />
        </svg>
      </div>
    </div>
  );
};
