/** @jsxImportSource theme-ui */
import { Flex, Themed } from "theme-ui";
import { useState } from "react";

import { Button } from "../../components/Button";

export const WordSelect = ({
  words,
  onSelect,
}: {
  words: string[];
  onSelect: (selected: string) => void;
}) => {
  const [selectedIndex, setIndex] = useState(0);
  return (
    <Flex
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        textAlign: "center",
        marginY: 2,
      }}
    >
      <Button
        disabled={selectedIndex === 0}
        onClick={() => {
          onSelect(words[selectedIndex - 1]);
          setIndex(selectedIndex - 1);
        }}
        label="<"
      />
      <Themed.h3 sx={{ flexGrow: 1, color: "primary" }}>{words[selectedIndex]}</Themed.h3>
      <Button
        disabled={selectedIndex === words.length - 1}
        onClick={() => {
          onSelect(words[selectedIndex + 1]);
          setIndex(selectedIndex + 1);
        }}
        label=">"
      />
    </Flex>
  );
};
