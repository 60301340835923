import { createSelector } from "reselect";
import { AppState } from "../initialState";

export const getConnection = (state: AppState) => state.connection.isConnected;
export const getConnectionErr = (state: AppState) => state.connection.socketErr;

export const getConnectionState = createSelector(
  [getConnection, getConnectionErr],
  (isConnected, connectionErr) => ({ isConnected, connectionErr })
);
