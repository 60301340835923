/** @jsxImportSource theme-ui */

import { useContext, useState, useEffect } from "react";

import { AudioContext } from "../../context/audioContext";

export const useAudio = (
  url: string | null,
  context: AudioContext,
  loop: boolean,
  volume?: string
) => {
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | undefined>(undefined);
  const [audioTrackSource, setTrackSource] = useState<AudioBufferSourceNode | undefined>(undefined);
  const [gainNode, setGainNode] = useState<GainNode | undefined>(undefined);
  const [isPlaying, setIsPlaying] = useState(false);

  // Load .mp3 URL and set the arrayBuffer
  useEffect(() => {
    async function initialize() {
      const requestUrl =
        (url as string)[0] === "/" ? `${window.location.origin}${url}` : (url as string);
      console.log("requesting", requestUrl);
      const response = await fetch(requestUrl, {
        method: "GET",
        mode: "cors",
        headers: { Origin: window.location.origin },
      });
      const arrayBuffer = await response.arrayBuffer();
      const buffer: AudioBuffer = await new Promise((resolve, reject) =>
        context.decodeAudioData(arrayBuffer, resolve, reject)
      );
      setAudioBuffer(buffer);
    }

    if (url && context) {
      initialize().catch((err) => {
        console.log("err", err);
      });
    }
  }, [url, context]);

  // launch playback when buffer is first set
  useEffect(() => {
    if (audioBuffer && !isPlaying) {
      setIsPlaying(true);

      const trackSource = context.createBufferSource();
      trackSource.buffer = audioBuffer;
      trackSource.loop = !!loop;

      // connect audio pipeline (track -> gain -> dest)
      const gain = context.createGain();
      trackSource.connect(gain);
      gain.connect(context.destination);

      trackSource.start();

      setTrackSource(trackSource);
      setGainNode(gain);

      if (!loop) {
        trackSource.addEventListener("ended", () => {
          setAudioBuffer(undefined);
          setIsPlaying(false);
        });
      }
    }
  }, [audioBuffer, isPlaying, context, loop]);

  // handle changes in audio
  useEffect(() => {
    if (volume && audioTrackSource && gainNode) {
      const vol = parseInt(volume, 10) / 100;
      gainNode.gain.setValueAtTime(vol, 0);
    }
  }, [volume, audioTrackSource, gainNode]);

  // when destroying, remove the track from the AudioContext
  useEffect(() => {
    return () => {
      audioTrackSource?.stop();
      audioTrackSource?.disconnect();
    };
  }, [audioTrackSource]);

  return [isPlaying];
};

export const AudioComponent = ({ url, loop }: { url: string; loop?: boolean }) => {
  const { context, volume } = useContext(AudioContext);
  useAudio(url, context, !!loop, volume);
  return <div></div>;
};
