/** @jsxImportSource theme-ui */
import { Flex, Themed } from "theme-ui";

import { PlayerMarker } from "../../components/PlayerMarker";
import { LOBBY_TITLES } from "../../game/constants/randomOptions";

export const AuthorDisplay = ({ authors }: { authors: string[] }) => (
  <div sx={{ flexGrow: 1, position: "relative" }}>
    <div
      sx={{
        width: "100%",
        textAlign: "center",
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          minHeight: "40vh",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {authors.map(
          (author, i) =>
            i < 8 && (
              <Flex sx={{ alignItems: "center" }} key={`author-${i}`}>
                <Themed.h3 sx={{ fontWeight: 300 }}>{LOBBY_TITLES[i]}</Themed.h3>
                <PlayerMarker name={author} colorIndex={i} isLarge />
              </Flex>
            )
        )}
        {authors.length > 8 && <Themed.h3>and others</Themed.h3>}
      </Flex>
    </div>
  </div>
);
