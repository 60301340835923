/** @jsxImportSource theme-ui */
import { Flex, Themed } from "theme-ui";
import { ChangeEvent, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import { Formik } from "formik";
import { LogOnMount } from "@amplitude/react-amplitude";

import { getConnectionState } from "../../store/connection/connectionSelector";
import { initializeAction } from "../../store/connection/connectionActions";
import { getLobbyState } from "../../store/lobby/lobbySelector";
import { clearAction, joinAction } from "../../store/lobby/lobbyActions";
import { Logo } from "../../components/TitleLogo";
import { Button } from "../../components/Button";
import { TextField } from "../../components/TextField";
import { FormRow } from "../../components/FormRow";
import { Pages, PageWrapper } from "../pageWrapper";
import { LoadingComponent } from "../../components/LoadingSpinner";
import { getLastPlayerName, setLastPlayerName } from "../../util/playername";

const mapToState = createSelector(
  [getConnectionState, getLobbyState],
  (connectionState, lobbyState) => ({
    isConnected: connectionState.isConnected,
    isConnectionError: connectionState.connectionErr !== null,
    roomKey: lobbyState.roomKey,
    lobbyError: lobbyState.lobbyError,
  })
);

const dispatchProps = {
  joinAction,
  connect: initializeAction,
  clearAction,
};

type Props = ReturnType<typeof mapToState> & typeof dispatchProps;

const OptionJoin: React.FC<Props> = ({
  isConnected,
  isConnectionError,
  roomKey,
  lobbyError,
  joinAction,
  clearAction,
  connect,
}) => {
  const [joinCode, setJoinCode] = useState("");

  useEffect(() => {
    connect();
  }, [connect]);

  // Remove state if a previous game exists
  useEffect(() => {
    if (!isConnected && roomKey) {
      clearAction();
    }
  }, [isConnected, roomKey]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setJoinCode(params.get("code") || "");
  }, [setJoinCode]);

  return (
    <PageWrapper type={Pages.LOBBY}>
      <LogOnMount eventType="JoinPage" />
      {roomKey && <Redirect to="/lobby" />}

      <Logo />

      <Themed.h4>Join a game</Themed.h4>

      <Formik
        initialValues={{ roomId: joinCode || "", name: getLastPlayerName() }}
        validate={(values) => {
          const errors: any = {};
          if (!values.roomId) {
            errors.roomId = "Required to have code";
          }
          if (!values.name) {
            errors.name = "Required to have name";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          joinAction({ roomKey: values.roomId, playerName: values.name });
          setLastPlayerName(values.name);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,
          isSubmitting,
        }) => (
          <form
            sx={{
              maxWidth: 600,
              marginLeft: "auto",
              marginRight: "auto",
            }}
            onSubmit={handleSubmit}
          >
            {!values.roomId && joinCode && setFieldValue("roomId", joinCode)}
            <FormRow>
              <TextField
                name="name"
                label="Player Name"
                min="1"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name ? errors.name : ""}
              />
            </FormRow>

            <FormRow>
              <TextField
                name="roomId"
                label="Code"
                min="6"
                max="6"
                value={values.roomId}
                onChange={(event: ChangeEvent<any>) => {
                  const value: string = event.target.value;
                  const split = value.split("code=");

                  if (split.length > 1) {
                    setFieldValue("roomId", split[1].toUpperCase());
                  } else {
                    setFieldValue("roomId", value.toUpperCase());
                  }

                  if (event.target.value === "") {
                    setJoinCode("");
                  }
                }}
                onBlur={handleBlur}
                error={touched.roomId ? errors.roomId : ""}
              />
            </FormRow>

            <Flex>
              <Button
                sx={{ margin: 3, fontSize: 3, backgroundColor: "text" }}
                type="submit"
                disabled={isSubmitting && !lobbyError}
                label="Join Friends"
              />
              <Button
                sx={{
                  margin: 3,
                  fontSize: 3,
                  backgroundColor: "secondary",
                }}
                type="button"
                to="/"
                label="Cancel"
              />
            </Flex>

            {!isConnected && <p>Connecting...</p>}
            {isConnectionError && (
              <p sx={{ color: "error" }}>Connection error. Please refresh and try again</p>
            )}
            {lobbyError && <p sx={{ color: "error" }}>{lobbyError}</p>}
            {isSubmitting && !lobbyError && <LoadingComponent isDarkTheme />}
          </form>
        )}
      </Formik>
    </PageWrapper>
  );
};

export const JoinPage = connect(mapToState, dispatchProps)(OptionJoin);
