import React from "react";
import { Route, Redirect } from "react-router-dom";
import { validateToken } from "./util/auth";
import { AUTH_USER_TOKEN_KEY } from "./config";

export const PrivateRoute = ({ component: Component, ...rest }: any & { component: any }) => {
  const isAuthorized = () => {
    return validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthorized() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/login?redirect=${rest.path}`,
            }}
          />
        );
      }}
    />
  );
};
