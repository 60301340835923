/** @jsxImportSource theme-ui */
import { Themed } from "theme-ui";
import { FC } from "react";
import { LogOnMount } from "@amplitude/react-amplitude";

import { Pages, PageWrapper } from "../../pages/pageWrapper";
import { Logo } from "../TitleLogo";

type Props = {
  error: Error;
};

export const ErrorFallback: FC<Props> = ({ error }) => {
  console.error(error);

  return (
    <PageWrapper type={Pages.LOBBY}>
      <LogOnMount eventType="ErrorPage" />
      <Logo />

      <Themed.h4>Error Encountered</Themed.h4>
      <Themed.p>
        An error has occurred. Please refresh and return to home page before attempting again.
      </Themed.p>
    </PageWrapper>
  );
};
