import { Auth } from "aws-amplify";

const prefix = (name: string) => `@connect/${name}`;

export const INITIALIZE = prefix("INITIALIZE");
export const CONNECTED = prefix("CONNECTED");
export const CONNECTION_ERR = prefix("CONNECTION_ERR");
export const DISCONNECT = prefix("DISCONNECT");
export const DISCONNECTED = prefix("DISCONNECTED");
export const SEND = prefix("SEND");

export const initializeAction = () => ({ type: INITIALIZE });
export const connectedAction = () => ({ type: CONNECTED });
export const connectionErrAction = ({ type }: any) => ({
  type: CONNECTION_ERR,
  payload: { type },
  // {wasClean, code, reason} unused but might be helpful in the future
});
export const disconnectAction = () => ({ type: DISCONNECT });
export const disconnectedAction = () => ({ type: DISCONNECTED });

export type SendAction = { action: string; data: Record<string, unknown> };
export const sendAction = async (payload: SendAction) => {
  let info;
  try {
    info = await Auth.currentSession();
  } catch (err) {
    // ignore user not logged in
  }
  return {
    type: SEND,
    // ensure repeat requests are not deduplicated with reqDate
    payload: {
      ...payload,
      data: { ...payload.data, token: info?.getAccessToken().getJwtToken(), reqDate: Date.now() },
    },
  };
};
