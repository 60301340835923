/** @jsxImportSource theme-ui */
import { Themed } from "theme-ui";
import { keyframes } from "@emotion/react";
import { ChangeEvent, useState } from "react";
import { useResponsiveValue } from "@theme-ui/match-media";
import { Formik } from "formik";
import nlp from "compromise";
import UilDiceFive from "@iconscout/react-unicons/icons/uil-dice-five";

import { shuffle } from "../../util/shuffle";
import { Button } from "../Button";
import { TextField } from "../TextField";
import { FormRow } from "../FormRow";

type FormProps = {
  /** label in or above the form input */
  label: string;
  /** options available when user clicks on the random button */
  randomOptions: string[];
  /** indicates if this option is currently active for the user */
  isActive: boolean;
  /** indicates if the form is for an action option */
  isAction: boolean;
  /** callback for submission */
  onSubmit: (value: string) => void;
};

const fadeInDown = keyframes({
  from: { opacity: 0, transform: "translate3d(0, -100%, 0)" },
  to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
});

export const OptionInputForm = ({
  label,
  randomOptions,
  isActive,
  isAction,
  onSubmit,
}: FormProps) => {
  const buttonIconSize = useResponsiveValue(["15", "15", "24"]);
  const [warning, setWarning] = useState("");
  const [submitted, setSubmitted] = useState<string[]>([]);

  const actionInputChangeCheck = (changeValue: string) => {
    if (changeValue === "") {
      setWarning("");
    } else if (changeValue.length < 10) {
      setWarning("Please enter a longer action");
    } else {
      setWarning("");
      const doc = nlp(changeValue);
      doc.verbs().toPastTense();
      if (doc.text() !== changeValue) {
        setWarning("Check that action is past tense");
      }
    }
  };

  return (
    <div data-cy={`option-${label}-form`}>
      {!isActive && (
        <Themed.h4 sx={{ color: "primary", textAlign: "center", margin: 4 }}>
          {submitted.length > 0 ? `${submitted.length} ${label}s submitted` : ""}
        </Themed.h4>
      )}

      {isActive && (
        <div>
          <Themed.h4 sx={{ textAlign: "center", margin: 4, marginTop: 5 }}>Add {label}s</Themed.h4>

          {isAction && (
            <p sx={{ textAlign: "center" }}>Actions should be past tense for better results</p>
          )}

          <Formik
            initialValues={{ option: "" }}
            onSubmit={(values, { setSubmitting }) => {
              onSubmit(values.option);
              values.option = "";
              setSubmitting(false);
              setWarning("");
            }}
          >
            {({
              values,
              errors,
              handleBlur,
              handleSubmit,
              setFieldValue,
              touched,
              isSubmitting,
            }) => (
              <form
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  animation: `0.2s ${fadeInDown}`,
                }}
                autoComplete="off"
                onSubmit={(element) => {
                  setSubmitted([values.option, ...submitted]);
                  return handleSubmit(element);
                }}
              >
                <FormRow>
                  <TextField
                    name="option"
                    label={label}
                    min="1"
                    max="60"
                    value={values.option}
                    onChange={(event: ChangeEvent<any>) => {
                      const changeValue = event.target.value;
                      setFieldValue("option", changeValue);
                      if (isAction) {
                        actionInputChangeCheck(changeValue);
                      }
                    }}
                    onBlur={handleBlur}
                    error={touched.option ? errors.option : ""}
                    warning={warning}
                    variation="small"
                    disabled={!isActive}
                    data-cy="option-input"
                  />

                  <Button
                    sx={{ margin: 3, fontSize: [1, 1, 2], backgroundColor: "text" }}
                    type="submit"
                    disabled={isSubmitting}
                    label="Submit"
                    variation="small"
                    data-cy="option-submit-button"
                  />
                  <Button
                    sx={{ backgroundColor: "text" }}
                    type="button"
                    name="randomize"
                    variation="small"
                    label={
                      (
                        <UilDiceFive sx={{ verticalAlign: "middle" }} size={buttonIconSize} />
                      ) as any
                    }
                    onClick={() => {
                      setFieldValue("option", shuffle(randomOptions)[0]);
                    }}
                  />
                </FormRow>
              </form>
            )}
          </Formik>
        </div>
      )}

      {isActive && (
        <div sx={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}>
          {submitted.map((submission, i) => (
            <p
              key={`${submission}-${submitted.length - i}`}
              sx={{
                color: "light",
                animation: `0.5s ${fadeInDown}`,
                margin: 1,
              }}
              data-cy="submitted-text"
            >
              {submission}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
