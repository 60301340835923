/** @jsxImportSource theme-ui */

import { Field } from "formik";

export const RadioGroup = ({
  fieldName,
  legend,
  options,
}: {
  fieldName: string;
  legend: string;
  options: string[];
}) => (
  <div sx={{ margin: 3 }}>
    <div id={`${fieldName}-group`}>{legend}</div>
    <div
      role="group"
      aria-labelledby={`${fieldName}-group`}
      sx={{ display: "flex", flexWrap: "wrap" }}
    >
      {options.map((option) => (
        <label
          sx={{
            display: "flex",
            cursor: "pointer",
            position: "relative",
            fontSize: [2, 3, 3],
            margin: 2,
          }}
        >
          <Field
            sx={{
              position: "absolute",
              left: "-9999px",
              "&:checked + span": {
                backgroundColor: "area", // background color
                "&:before": { boxShadow: "inset 0 0 0 0.35em #000" },
              },
            }}
            type="radio"
            name={fieldName}
            key={`${fieldName}-${option}`}
            value={option}
          />
          <span
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 3,
              borderRadius: "99em",
              transition: "0.25s ease",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: "area",
              },
              "&:before": {
                display: "flex",
                flexShrink: 0,
                content: "''",
                backgroundColor: "#fff",
                width: "1.25em",
                height: "1.25em",
                borderRadius: "50%",
                marginRight: "0.375em",
                transition: "0.25s ease",
                boxShadow: "inset 0 0 0 0.125em",
              },
            }}
          >
            {option}
          </span>
        </label>
      ))}
    </div>
  </div>
);
