/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";
import { keyframes } from "@emotion/react";
import { FC, useContext, useEffect, useState } from "react";
import UilVolume from "@iconscout/react-unicons/icons/uil-volume";
import UilVolumeMute from "@iconscout/react-unicons/icons/uil-volume-mute";
import UilSlidersVertical from "@iconscout/react-unicons/icons/uil-sliders-v";

import { AudioContext } from "../../context/audioContext";
import { Button } from "../Button";

const fadeInLeft = keyframes({
  from: { opacity: 0, transform: "translate3d(-100%, 0, 0)" },
  to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
});

export const VolumeControl: FC<unknown> = () => {
  const { context, isMuted } = useContext(AudioContext);
  const [isOpen, setOpen] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [closeTimer, setCloseTimer] = useState<number | null>(null);
  const [contextState, setContextState] = useState(context.state);
  const volumeSize = useResponsiveValue(["30", "30", "45"]);
  const SoundIcon = isMuted ? <UilVolumeMute size={volumeSize} /> : <UilVolume size={volumeSize} />;

  useEffect(() => {
    setIsIOS(
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
        navigator.platform
      ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }, [setIsIOS]);

  useEffect(() => {
    setContextState(context.state);
    context.addEventListener("statechange", () => {
      setContextState(context.state);
    });
  }, [context]);

  const closeTimeout = (time: number) =>
    window.setTimeout(() => {
      console.log("timeout", time);
      setOpen(false);
    }, time);

  const createTimer = () => {
    if (closeTimer) {
      window.clearTimeout(closeTimer);
    }
    setCloseTimer(closeTimeout(2000));
  };

  // Allow 5 seconds on mobile phones after touching
  const touchEndTimerAdjustTimer = () => {
    // TODO: fix interactions
    // setTimeout(() => {
    //   if (closeTimer) {
    //     window.clearTimeout(closeTimer);
    //   }
    //   setCloseTimer(closeTimeout(5000));
    // }, 500);
  };

  // Cancel timer if mouse is hovering over content
  const hoverEnterCancelTimer = () => {
    if (closeTimer) {
      window.clearTimeout(closeTimer);
      setCloseTimer(null);
    }
  };

  // restart timer if mouse leaves the content
  const hoverLeaveAdjustTimer = () => {
    if (closeTimer) {
      window.clearTimeout(closeTimer);
    }
    setCloseTimer(closeTimeout(1000));
  };

  return (
    <AudioContext.Consumer>
      {({ isMuted, volume, setAudio }) => (
        <div sx={{ position: "relative" }}>
          {contextState === "suspended" ? (
            <Button
              label="Enable Audio"
              onClick={() => {
                context.resume();
              }}
            />
          ) : (
            <Button
              variation="small"
              name="volume-menu"
              label={(<UilSlidersVertical size={volumeSize} />) as any}
              onClick={() => {
                if (isOpen) {
                  if (closeTimer) {
                    clearTimeout(closeTimer);
                  }
                  setCloseTimer(null);
                } else {
                  createTimer();
                }
                setOpen(!isOpen);
              }}
              onTouchEnd={touchEndTimerAdjustTimer}
            />
          )}
          {isOpen && (
            <div
              onMouseOver={hoverEnterCancelTimer}
              onMouseLeave={hoverLeaveAdjustTimer}
              sx={{
                position: "absolute",
                left: ["58px", "70px", "85px"],
                bottom: ["6px", "10px"],
                backgroundColor: "primary",
                animation: `0.2s ${fadeInLeft}`,
              }}
            >
              <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
                <div sx={{ padding: 3 }}>
                  <input
                    type="range"
                    min="1"
                    max="100"
                    value={volume}
                    onChange={(e) => {
                      setAudio({ isMuted: false, volume: e.target.value });
                      touchEndTimerAdjustTimer();
                    }}
                  />
                </div>
                <Button
                  variation="small"
                  name="mute-toggle"
                  label={SoundIcon as any}
                  onClick={() => {
                    setAudio({
                      isMuted: !isMuted,
                      volume: isMuted ? "100" : "0",
                    });
                  }}
                  onTouchEnd={touchEndTimerAdjustTimer}
                />
              </Flex>
              {isIOS && (
                <Flex
                  sx={{
                    padding: 1,
                    color: "backdrop",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  Ensure silence mode off
                </Flex>
              )}
            </div>
          )}
        </div>
      )}
    </AudioContext.Consumer>
  );
};
