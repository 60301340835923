import { CONNECTED, DISCONNECTED, CONNECTION_ERR } from "./connectionActions";
import connectionState from "./connectionState";

const reducer = (state = connectionState, action: any) => {
  switch (action.type) {
    case CONNECTED:
      return { isConnected: true, socketErr: null };
    case DISCONNECTED:
      return { ...connectionState, socketErr: state.socketErr };
    case CONNECTION_ERR:
      return { ...state, socketErr: action.payload };
    default:
      return state;
  }
};
export default reducer;
