/** @jsxImportSource theme-ui */
import { Themed } from "theme-ui";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { LogOnMount } from "@amplitude/react-amplitude";
import "yup-phone";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as schema from "password-validator";

import { Button } from "../../components/Button";
import { FormRow } from "../../components/FormRow";
import { TextField } from "../../components/TextField";
import { AuthWrapper } from "./wrapper";
import { AUTH_PASS } from "../../config";

const passwordSchema = new schema();
passwordSchema.is().min(8).has().uppercase().has().lowercase().has().digits();

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Name is too short")
    .max(50, "Name is too long")
    .required("Name input required"),
  lastName: Yup.string()
    .min(2, "Name is too short")
    .max(50, "Name is too long")
    .required("Name input required"),
  email: Yup.string().email().required("Please input an email"),
  password: Yup.string()
    .required()
    .test(
      "password",
      "Password requires 8 characters and at least one of each (number, uppercase, and lowercase character)",
      (value) => (passwordSchema.validate(String(value), { list: true }) as string[]).length === 0
    ),
  confirm: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), ""], "Passwords much match"),
});

export const Signup = () => {
  const [username, setUsername] = useState<string | null>(null);
  const [error, setError] = useState<string>();

  return (
    <AuthWrapper>
      {username ? (
        <Redirect to={{ pathname: `/confirm/${username}` }} />
      ) : (
        <div>
          <Themed.h4>Create an account</Themed.h4>
          <LogOnMount eventType="signUpPage" />
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              confirm: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              setError("");
              Auth.signUp({
                username: values.email.toLowerCase(),
                password: values.password,
                attributes: {
                  email: values.email,
                  name: `${values.firstName} ${values.lastName}`,
                },
              })
                .then(() => {
                  console.debug("success");
                  localStorage.setItem(AUTH_PASS, values.password);
                  setUsername(values.email);
                })
                .catch((err) => {
                  console.error("error");
                  setError(err.message);
                });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <TextField
                    name="firstName"
                    label="First Name(s)"
                    autoComplete="first-name"
                    required
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firstName ? errors.firstName : ""}
                    data-cy="first-name-input"
                  />
                </FormRow>

                <FormRow>
                  <TextField
                    name="lastName"
                    label="Last Name(s)"
                    autoComplete="last-name"
                    required
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.lastName ? errors.lastName : ""}
                    data-cy="last-name-input"
                  />
                </FormRow>

                <FormRow>
                  <TextField
                    name="email"
                    label="Email"
                    type="email"
                    autoComplete="email"
                    required
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email ? errors.email : ""}
                    data-cy="email-input"
                  />
                </FormRow>

                {/* <FormRow>
                  <TextField
                    name="phone"
                    label="Phone Number"
                    type="tel"
                    autoComplete="phone"
                    required
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.phone}
                  />
                </FormRow> */}

                <FormRow>
                  <TextField
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    required
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password ? errors.password : ""}
                    data-cy="password-input"
                  />
                </FormRow>

                <FormRow>
                  <TextField
                    name="confirm"
                    label="Confirm Password"
                    type="password"
                    autoComplete="new-password"
                    required
                    value={values.confirm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.confirm ? errors.confirm : ""}
                    data-cy="password-confirm-input"
                  />
                </FormRow>

                {error && (
                  <p
                    sx={{
                      color: "error",
                      fontSize: [1, 2, 2],
                    }}
                  >
                    {error}
                  </p>
                )}

                <Button
                  sx={{ margin: 3, fontSize: 3, backgroundColor: "text" }}
                  type="submit"
                  label="Register"
                  data-cy="submit-button"
                />
              </form>
            )}
          </Formik>
        </div>
      )}
    </AuthWrapper>
  );
};
