/** @jsxImportSource theme-ui */
import { Flex, Container, useColorMode } from "theme-ui";
import { useContext } from "react";
import { Amplitude } from "@amplitude/react-amplitude";

import { AudioContext } from "../context/audioContext";
import { useAudio } from "../components/Audio";
import { VolumeControl } from "../components/VolumeControl";
import styles from "./pageWrapper.module.scss";

export enum Pages {
  LOBBY = "lobby",
  GAME = "game",
}

export const PageWrapper = ({ type, children }: { type: Pages; children: React.ReactNode }) => {
  const [, setColorMode] = useColorMode();
  if (type === Pages.LOBBY) {
    setColorMode("dark");
  }
  if (type === Pages.GAME) {
    setColorMode("light");
  }

  const { context, volume } = useContext(AudioContext);
  // from YouTube Music Free Audio (Ambient / Inspirational)
  useAudio("/audio/background-music.mp3", context, true, `${parseInt(volume, 10) * 0.8}`);

  return (
    <Amplitude
      eventProperties={{
        scope: [type === Pages.LOBBY ? "lobby" : "game"],
      }}
    >
      <div
        className={type === Pages.LOBBY ? styles["dark"] : styles["light"]}
        sx={{
          backgroundColor: "backdrop",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: ["100%", "600px", "800px", "1000px"],
        }}
      >
        <Flex>
          <div
            sx={{
              position: "relative",
              minHeight: "100vh",
              width: ["10%", "10%", "10%", "100px"],
            }}
          >
            <div sx={{ position: "fixed", bottom: 4, left: [0, 0, 0, 1], zIndex: 10 }}>
              <VolumeControl />
            </div>
          </div>
          <Container sx={{ width: ["89%", "89%", "89%", "calc(100% - 110px)"] }}>
            <Flex
              sx={{
                minHeight: "100vh",
                flexWrap: "wrap",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              {children}
            </Flex>
          </Container>
        </Flex>
      </div>
    </Amplitude>
  );
};
