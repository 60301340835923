/** @jsxImportSource theme-ui */

import { useState, useEffect, FC } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import { getGamePhase, readyNextStage, getIsHost } from "../selector/gameSelector";

const mapToState = createSelector(
  [getIsHost, getGamePhase, readyNextStage],
  (isHost, gamePhase, continueToNext) => ({
    isHost,
    gamePhase,
    continueToNext,
  })
);

type Props = {
  nextSceneHandler: () => void;
} & ReturnType<typeof mapToState>;

const Host: FC<Props> = ({ isHost, gamePhase, continueToNext, nextSceneHandler }) => {
  const [runningTimer, setTimer] = useState<any>();

  useEffect(() => {
    if (continueToNext) {
      nextSceneHandler();
      console.debug("cleartimer");
      clearTimeout(runningTimer.timer);
    }
  }, [continueToNext, nextSceneHandler, runningTimer]);

  useEffect(() => {
    if (!runningTimer || runningTimer.phase !== gamePhase.phase) {
      if (runningTimer) {
        clearTimeout(runningTimer.timer);
      }

      if (isHost && gamePhase.seconds) {
        console.debug("set timer", JSON.stringify(gamePhase));
        const phaseDuration = (gamePhase?.seconds || 0) * 1000;
        const timeout = setTimeout(() => {
          console.debug("timer expired", JSON.stringify(gamePhase));
          nextSceneHandler();
        }, phaseDuration);
        setTimer({ timer: timeout, phase: gamePhase.phase });
      }
    }
  }, [isHost, gamePhase, nextSceneHandler, runningTimer]);

  // Remove the timer when HostComponent is destroyed
  useEffect(() => {
    return () => {
      if (runningTimer) {
        clearTimeout(runningTimer.timer);
      }
    };
  }, [runningTimer]);

  return <div />;
};

export const HostComponent = connect(mapToState)(Host);
