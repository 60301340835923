/** @jsxImportSource theme-ui */
import { Flex, Themed } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";
import { FormEvent, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Auth } from "aws-amplify";
import { LogOnMount } from "@amplitude/react-amplitude";

import { AuthWrapper } from "../auth/wrapper";
import { StripeCardElement } from "@stripe/stripe-js";
import { getClientSecret, getCountryCode } from "../../store/payment/paymentSelector";
import { getSecretAction } from "../../store/payment/paymentActions";
import { isPaidUser } from "../../util/auth";
import { LoadingComponent } from "../../components/LoadingSpinner";

const mapToState = createSelector([getClientSecret, getCountryCode], (clientSecret, country) => ({
  clientSecret,
  country,
}));

const dispatchProps = {
  getSecret: getSecretAction,
};

type Props = ReturnType<typeof mapToState> & typeof dispatchProps;

export const Account = ({ clientSecret, country, getSecret }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | undefined>();
  const [redirectLogin, setRedirect] = useState(false);
  const [hasPaid, setPaid] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    isPaidUser().then((isPaid) => {
      setPaid(isPaid);
    });
  }, [setPaid]);

  useEffect(() => {
    if (!clientSecret) {
      Auth.currentUserInfo().then((info) => {
        getSecret(info.username);
      });
    }
  }, [clientSecret, getSecret]);

  const handleSubmit = async (event: FormEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log("stripe not loaded");
      return;
    }

    setLoading(true);
    const result = await stripe.confirmCardPayment(`${clientSecret}`, {
      payment_method: {
        card: elements.getElement(CardElement) as StripeCardElement,
        billing_details: {},
      },
    });
    setLoading(false);

    if (result.error) {
      setError(result?.error?.message);
    } else {
      // The payment has been processed!
      if (result?.paymentIntent?.status === "succeeded") {
        Auth.signOut();
        setRedirect(true);
      } else {
        setError("Payment could not be processed. Verify and try again.");
      }
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#222222",
        iconColor: "#7A5868",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: useResponsiveValue(["12px", "14px", "14px", "16px"]),
        "::placeholder": {
          color: "#7A5868",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const originalPrice = "$4.99 " + (country === "CA" ? "CAD" : country === "US" ? "" : "USD");
  const currencyDisplay = "$1.99 " + (country === "CA" ? "CAD" : country === "US" ? "" : "USD");

  return (
    <AuthWrapper>
      <LogOnMount
        eventType="accountPage"
        eventProperties={(props: any) => ({
          ...props,
          hasPaid,
        })}
      />
      {redirectLogin && (
        <div>
          <LogOnMount eventType="payment success" />
          <Redirect to={{ pathname: "/login" }} />
        </div>
      )}
      {hasPaid ? (
        <div>
          <h4>Thank you for joining!</h4>
        </div>
      ) : (
        <div sx={{ marginBottom: 6 }}>
          <Themed.h4>Join Early Access</Themed.h4>

          <p>
            We're currently welcoming new players with a launch discount! Join today and you can
            come along with our game as it continues to expand and improve!
          </p>

          <p sx={{ color: "primary", fontWeight: "600" }}>
            A one-time purchase will unlock lifetime access to:
          </p>

          <ul sx={{ textAlign: "left", width: ["100%", "100%", "70%"], color: "primary" }}>
            <li>Host unlimited games</li>
            <li>Invite more than 4 friends to play</li>
            <li>All guests play for free without needing an account</li>
            {/* <li>Previous games stored to your account</li> */}
          </ul>

          <form onSubmit={handleSubmit} sx={{ backgroundColor: "primary", padding: [3, 5] }}>
            <h4
              sx={{
                color: "area",
                fontFamily: `"Helvetica Neue", Helvetica, sans-serif`,
                marginBottom: "10px",
                marginTop: 0,
              }}
            >
              Checkout
            </h4>
            <Flex
              sx={{
                color: "area",
                fontFamily: `"Helvetica Neue", Helvetica, sans-serif`,
                justifyContent: "space-between",
                fontSize: [0, 1],
                margin: 3,
              }}
            >
              <span>Our Story - Early Access Member</span>
              <Flex sx={{ flexDirection: "column" }}>
                <span sx={{ textDecoration: "line-through", opacity: 0.8, fontSize: ".9rem" }}>
                  {originalPrice}
                </span>
                <span sx={{ fontWeight: "600" }}>{currencyDisplay}</span>
              </Flex>
            </Flex>
            <CardElement
              sx={{ backgroundColor: "white", padding: "10px" }}
              options={CARD_ELEMENT_OPTIONS}
              onReady={() => {
                console.log("CardElement [ready]");
              }}
              onChange={(event) => {
                console.log("CardElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardElement [blur]");
              }}
              onFocus={() => {
                console.log("CardElement [focus]");
              }}
            />
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <button
                sx={{
                  backgroundColor: "rgb(84, 105, 212)",
                  color: "white",
                  fontFamily: `"Helvetica Neue", Helvetica, sans-serif`,
                  width: "100%",
                  padding: 4,
                  fontSize: "16px",
                }}
                type="submit"
                disabled={!stripe}
              >
                Pay now
              </button>
            )}

            {error && (
              <div>
                <LogOnMount
                  eventType="payment error"
                  eventProperties={(props: any) => ({
                    ...props,
                    error,
                  })}
                />
                <p sx={{ color: "red", fontFamily: `"Helvetica Neue", Helvetica, sans-serif` }}>
                  {error}
                </p>
              </div>
            )}

            {/* TODO: add section for troubleshooting bad payments */}
          </form>

          <p sx={{ fontSize: ".9rem", marginBottom: 8 }}>
            This game is currently in early access and the game is subject to change.
          </p>
        </div>
      )}
    </AuthWrapper>
  );
};

export const AccountPage = connect(mapToState, dispatchProps)(Account);
