/** @jsxImportSource theme-ui */

import { FC } from "react";
import { MagicSpinner } from "react-spinners-kit";

type Props = {
  size?: number;
  isDarkTheme?: boolean;
};

export const LoadingComponent: FC<Props> = ({ size = 100, isDarkTheme = false }) => {
  return (
    <div sx={{ marginLeft: "auto", marginRight: "auto", width: "100px" }} data-cy="loading-spinner">
      <MagicSpinner size={size} color={isDarkTheme ? "#EAEADF" : "#302f2d"} />
    </div>
  );
};
