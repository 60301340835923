import { createSelector } from "reselect";

import { AppState } from "../../store/initialState";
import { filterOut, getWordSelection, shuffle } from "../../util/shuffle";
import { ACTION_RANDOMS, PLACE_RANDOMS } from "../constants/randomOptions";
import { getConfigData } from "../../store/lobby/lobbySelector";

export const getPlayerNames = (state: AppState) =>
  state.lobby.lobbyData.map((data) => ({
    name: data.name,
    connectionId: data.userConnection,
  }));

export const getGameData = (state: AppState) =>
  state.lobby.lobbyData.find((data) => data.userConnection === "DATA");

export const getMyName = (state: AppState) => state.lobby.myName;

export const getAuthors = (state: AppState) =>
  state.lobby.lobbyData.filter((data) => !!data.name).sort((a, b) => a.ttl - b.ttl);

const getAuthorLookup = (state: AppState) =>
  state.lobby.lobbyData
    .filter((data) => !!data.name)
    .sort((a, b) => a.ttl - b.ttl)
    .reduce(
      (prev, { name, userConnection }, index) => ({ ...prev, [userConnection]: { name, index } }),
      {}
    ) as {
    [key: string]: { name: string; index: number };
  };

export const getAuthorInputCount = createSelector(
  [getGameData, getAuthorLookup],
  (gameData, authorLookup) =>
    gameData
      ? (Object.keys(gameData?.options).reduce((prev, curr) => {
          const options = gameData.options[curr];
          return {
            ...prev,
            [authorLookup[curr].name]: {
              count: options.action.length + options.name.length + options.place.length,
              index: authorLookup[curr].index,
            },
          };
        }, {}) as { [key: string]: { count: number; index: number } })
      : {}
);

export const getShareLinkId = (state: AppState) =>
  state.lobby.lobbyData.find((data) => data.userConnection === "DATA")?.uuid;

export const getRound = createSelector(getGameData, (gameData) => gameData?.round.number || null);

export const getCurrentRound = createSelector(
  [getGameData, getMyName, getPlayerNames, getRound],
  (gameData, name, playerNames, round) => {
    const myConnection = playerNames.find((player) => player.name === name)?.connectionId;
    if (!myConnection || !round) return null;
    return gameData?.rounds[round - 1];
  }
);

export const currentRoundScenes = createSelector([getCurrentRound], (currentRound) => {
  return Object.keys(currentRound || {})
    .map((key) => ({
      ...(currentRound || {})[key],
      sessionId: key,
    }))
    .filter((scene) => !!scene?.scene);
});

export const finalRoundScenes = createSelector(
  [getGameData, getConfigData],
  (gameData, configData) =>
    gameData?.rounds
      .flatMap((round, i) => {
        if (i !== (configData?.lastRound || 4) - 1) {
          return Object.keys(round).map((key) => ({
            ...(round || {})[key],
            sessionId: key,
          }));
        } else {
          const largest = Object.keys(gameData?.votes).reduce(
            (prev, curr) =>
              (curr && !prev) || gameData?.votes[curr] > gameData?.votes[prev] ? curr : prev,
            ""
          );
          return largest ? [{ ...round[largest], sessionId: largest }] : [];
        }
      })
      .filter((scene) => !!scene?.scene)
);

export const getPlayerOptions = createSelector(
  [getGameData, getMyName, getAuthors],
  (gameData, myName, authors) => {
    if (!gameData) return null;

    // Allow all players to use any name
    const name = shuffle(
      Object.values(gameData?.options).reduce(
        (prev, curr) => [...prev, ...curr.name],
        [] as string[]
      )
    );

    const unusedOptions: any = Object.keys(gameData.options).reduce(
      (connections, connectionId) => ({
        ...connections,
        [connectionId]: Object.keys(gameData.options[connectionId]).reduce((prev, key) => {
          return key === "name"
            ? prev
            : {
                ...prev,
                [key]: filterOut(
                  (gameData.options[connectionId] as any)[key],
                  gameData.usedOptions[`${key}s`] // TODO: fix me plz
                ),
              };
        }, {}),
      }),
      {}
    );
    const myConnection = authors.find((a) => a.name === myName)?.userConnection;
    const isReversedFinalRound = gameData.round.stage === 3;
    const action = getWordSelection(
      Object.keys(unusedOptions).reduce(
        (prev, curr) => ({ ...prev, [curr]: unusedOptions[curr].action }),
        {}
      ),
      myConnection || "",
      shuffle(ACTION_RANDOMS),
      isReversedFinalRound
    );
    const place = getWordSelection(
      Object.keys(unusedOptions).reduce(
        (prev, curr) => ({ ...prev, [curr]: unusedOptions[curr].place }),
        {}
      ),
      myConnection || "",
      shuffle(PLACE_RANDOMS),
      isReversedFinalRound
    );

    return { name, action, place };
  }
);

export const getShareLink = createSelector([getShareLinkId], (linkId) => {
  if (linkId) {
    return `${window.location.protocol}//share.${window.location.hostname}/play/${linkId}`;
  }
});

export const getSubmittedStatus = createSelector(
  [getAuthorLookup, getGameData],
  (authorLookup, gameData) => {
    const round = gameData?.round.number || 0;
    return Object.keys(authorLookup).map((connectionId) => ({
      ...authorLookup[connectionId],
      hasSubmitted: gameData?.rounds[round - 1] && !!gameData?.rounds[round - 1][connectionId]?.gif,
    }));
  }
);

export const getTemplateStatus = createSelector(
  [getAuthorLookup, getGameData],
  (authorLookup, gameData) => {
    const round = gameData?.round.number || 0;
    return Object.keys(authorLookup).map((connectionId) => ({
      ...authorLookup[connectionId],
      hasSubmitted:
        gameData?.rounds[round - 1] && !!gameData?.rounds[round - 1][connectionId]?.template,
    }));
  }
);
