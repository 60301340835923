/** @jsxImportSource theme-ui */

import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { LogOnMount } from "@amplitude/react-amplitude";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import * as schema from "password-validator";

import { Button } from "../../components/Button";
import { FormRow } from "../../components/FormRow";
import { TextField } from "../../components/TextField";
import { AuthWrapper } from "./wrapper";

const passwordSchema = new schema();
passwordSchema.is().min(8).has().uppercase().has().lowercase().has().digits();

const PasswordResetSchema = Yup.object().shape({
  code: Yup.number().required(),
  password: Yup.string()
    .required()
    .test(
      "password",
      "Password requires 8 characters and at least one of each (number, symbol, uppercase, and lowercase character)",
      (value) => (passwordSchema.validate(String(value), { list: true }) as string[]).length === 0
    ),
  confirm: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), ""], "Passwords much match"),
});

export const PasswordReset = () => {
  const [error, setError] = useState<string>();
  const [isSuccess, setSuccess] = useState(false);
  const { username } = useParams<{ username: string }>();

  return (
    <AuthWrapper>
      <LogOnMount eventType="passwordResetPage" />
      {isSuccess ? (
        <Redirect to={{ pathname: "/login" }} />
      ) : (
        <Formik
          initialValues={{ code: "", password: "", confirm: "" }}
          validationSchema={PasswordResetSchema}
          onSubmit={(values) => {
            Auth.forgotPasswordSubmit(username.trim(), values.code.trim(), values.password.trim())
              .then(() => {
                console.log("success");
                setSuccess(true);
              })
              .catch((err) => {
                setError(err.message);
                console.log("err", err);
              });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormRow>
                <TextField
                  name="code"
                  label="Enter verification code"
                  required
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.code ? errors.code : ""}
                />
              </FormRow>

              <FormRow>
                <TextField
                  name="password"
                  label="New Password"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password ? errors.password : ""}
                />
              </FormRow>

              <FormRow>
                <TextField
                  name="confirm"
                  label="Confirm Password"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={values.confirm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.confirm ? errors.confirm : ""}
                />
              </FormRow>

              {error && (
                <p
                  sx={{
                    color: "error",
                    fontSize: [1, 2, 2],
                  }}
                >
                  {error}
                </p>
              )}

              <Button
                sx={{ margin: 3, fontSize: 3, backgroundColor: "text" }}
                type="submit"
                label="Confirm Email"
              />
            </form>
          )}
        </Formik>
      )}
    </AuthWrapper>
  );
};
