/** @jsxImportSource theme-ui */
import { Themed } from "theme-ui";
import { useState, useEffect } from "react";
import { Amplitude } from "@amplitude/react-amplitude";

import { CountdownComponent } from "../../components/Countdown";
import { LoadingComponent } from "../../components/LoadingSpinner";

import { PickOptions } from "../components/pick-options";
import { PickGif } from "../components/pick-gif";

export enum Screens {
  PickOptions = "pick",
  PickGif = "gif",
  Loading = "loading",
}

export const PickScene = ({
  playerOptions,
  roomKey,
  selectedTemplate,
  phaseDuration,
  interact,
}: any) => {
  const [{ names, actions, places }, setOptions] = useState({
    names: [] as string[],
    actions: [] as string[],
    places: [] as string[],
  });
  const [screen, setScreen] = useState(Screens.PickOptions);
  const [finalString, setFinal] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selected, setSelected] = useState({});
  const duration = phaseDuration;

  useEffect(() => {
    if (names.length === 0) {
      setOptions({
        names: playerOptions?.name?.length > 0 ? playerOptions?.name : ["Jen", "Bart"],
        actions: playerOptions?.action,
        places: playerOptions?.place,
      });
    }
  }, [names, playerOptions]);

  return (
    <Amplitude>
      {({ logEvent }: any) => (
        <div>
          <CountdownComponent millisecondDuration={duration} />
          {screen === Screens.PickOptions && names.length > 0 && (
            <div>
              <Themed.h4 data-cy="pick-scene-title">Fill in the details of your story</Themed.h4>
              <PickOptions
                template={selectedTemplate}
                names={names}
                actions={actions}
                places={places}
                selectString={(final, selected, selectedAction) => {
                  const scene = final.join("");
                  setFinal(scene);
                  setSelected(selected);
                  setSelectedAction(selectedAction);
                  setScreen(Screens.PickGif);
                  logEvent("set scene", { scene: scene });

                  interact({
                    roomKey,
                    payload: {
                      type: "update-pick",
                      scene,
                      selected,
                    },
                  });
                }}
              />
            </div>
          )}

          {screen === Screens.PickGif && (
            <PickGif
              selectGif={(gif) => {
                interact({
                  roomKey,
                  payload: {
                    type: "update-pick",
                    scene: finalString,
                    gif: gif.id,
                    selected,
                  },
                });
                setScreen(Screens.Loading);
                logEvent("set gif", { gifId: gif.id, gifUrl: gif.bitly_gif_url });
              }}
              selectedAction={selectedAction}
              finalScene={finalString}
            />
          )}

          {screen === Screens.Loading && <LoadingComponent />}
        </div>
      )}
    </Amplitude>
  );
};
