import { createSelector } from "reselect";
import { NARRATION_API_BASE } from "../../config";
import { AppState } from "../../store/initialState";
import { GamePhase } from "../constants/gamePhases";
import {
  RoundOneIntroSeconds,
  SceneCreationSeconds,
  LoadFirstWatchSeconds,
  WatchSceneSeconds,
  TemplateSelectSeconds,
  AddOptionsSeconds,
  VotingSeconds,
  LoadWatchSeconds,
} from "../constants/timing";
import { getConfigData } from "../../store/lobby/lobbySelector";

export type GameState = {
  phase: GamePhase;
  narration?: string;
  startTime?: number;
  roundNumber?: number;
  lastRound?: number;

  // host only
  seconds?: number;
};

export const getRoomKey = (state: AppState) => state.lobby.roomKey;

export const getGameData = (state: AppState) =>
  state.lobby.lobbyData.find((data) => data.userConnection === "DATA");

export const getHostData = (state: AppState) =>
  state.lobby.lobbyData.find((data) => data.host === true);

export const getNumberOfAuthors = (state: AppState) =>
  state.lobby.lobbyData.filter((data) => !!data.name).length;

export const getIsHost = (state: AppState) => state.lobby.isHost;

export const getRound = createSelector(getGameData, (gameData) => gameData?.round);

export const getScenes = createSelector([getGameData, getRound], (gameData, round) => {
  if (!gameData || !round) return [];
  return Object.values(gameData.rounds[round.number - 1] || []).map(
    (playerEntry) => playerEntry.scene
  );
});

export const getGamePhase = createSelector(
  [getGameData, getIsHost, getScenes, getConfigData],
  (gameData, isHost, scenes, configData): GameState => {
    if (!gameData) {
      return { phase: isHost ? GamePhase.SelectTemplate : GamePhase.Waiting };
    }
    const {
      round: { number: roundNumber, stage, startTime },
    } = gameData;
    const commonData = { startTime, roundNumber, lastRound: configData.lastRound };
    const narrationPrefix = `${NARRATION_API_BASE}/${configData.voice}-${configData.language}`;
    switch (roundNumber) {
      // Round 1
      case 1:
        if (stage === 1) {
          return {
            phase: isHost ? GamePhase.SelectTemplate : GamePhase.AddOptions,
            seconds: RoundOneIntroSeconds,
            narration: `${narrationPrefix}-1-1.mp3`,
            ...commonData,
          };
        }
        if (stage === 2) {
          return {
            phase: isHost ? GamePhase.SelectOptions : GamePhase.Waiting,
            seconds: SceneCreationSeconds,
            narration: `${narrationPrefix}-1-2.mp3`,
            ...commonData,
          };
        }
        if (stage === 3) {
          return {
            phase: GamePhase.Watch,
            seconds: WatchSceneSeconds * scenes.length + LoadFirstWatchSeconds,
            narration: `${narrationPrefix}-1-3.mp3`,
            ...commonData,
          };
        }

      // Last round
      // eslint-disable-next-line no-fallthrough
      case configData.lastRound:
        if (stage === 1) {
          return {
            phase: GamePhase.SelectTemplate,
            seconds: TemplateSelectSeconds,
            narration: `${narrationPrefix}-4-1.mp3`,
            ...commonData,
          };
        }
        if (stage === 2) {
          return {
            phase: GamePhase.SelectOptions,
            seconds: SceneCreationSeconds,
            narration: `${narrationPrefix}-4-2.mp3`,
            ...commonData,
          };
        }
        if (stage === 3) {
          return {
            phase: GamePhase.Watch,
            seconds: WatchSceneSeconds * scenes.length + LoadWatchSeconds,
            narration: `${narrationPrefix}-4-3.mp3`,
            ...commonData,
          };
        }
        if (stage === 4) {
          return {
            phase: GamePhase.Voting,
            seconds: VotingSeconds,
            narration: `${narrationPrefix}-4-4.mp3`,
            ...commonData,
          };
        }

      // eslint-disable-next-line no-fallthrough
      case configData.lastRound + 1:
        if (stage === 1) {
          return {
            phase: GamePhase.FinalReview,
            narration: `${narrationPrefix}-4-5.mp3`,
            ...commonData,
          };
        }

      // eslint-disable-next-line no-fallthrough
      default:
        // rounds 2 -> 3 or middle rounds when custom
        if (roundNumber > 1 && roundNumber < configData.lastRound) {
          if (stage === 1) {
            return {
              phase: GamePhase.SelectTemplate,
              seconds: TemplateSelectSeconds,
              narration: roundNumber < 4 ? `${narrationPrefix}-${roundNumber}-1.mp3` : undefined,
              ...commonData,
            };
          }
          if (stage === 2) {
            return {
              phase: GamePhase.AddOptions,
              seconds: AddOptionsSeconds,
              narration: roundNumber < 4 ? `${narrationPrefix}-${roundNumber}-2.mp3` : undefined,
              ...commonData,
            };
          }
          if (stage === 3) {
            return {
              phase: GamePhase.SelectOptions,
              seconds: SceneCreationSeconds,
              narration: roundNumber < 4 ? `${narrationPrefix}-${roundNumber}-3.mp3` : undefined,
              ...commonData,
            };
          }
          if (stage === 4) {
            return {
              phase: GamePhase.Watch,
              seconds: WatchSceneSeconds * scenes.length + LoadWatchSeconds,
              narration: roundNumber < 4 ? `${narrationPrefix}-${roundNumber}-4.mp3` : undefined,
              ...commonData,
            };
          }
        }
        return { phase: GamePhase.Waiting, ...commonData };
    }
  }
);

export const readyNextStage = createSelector(
  [getIsHost, getGameData, getHostData, getNumberOfAuthors, getGamePhase],
  (isHost, gameData, hostData, numberOfAuthors, gamePhase) => {
    if (!isHost || !gameData?.round || !hostData) return null;

    if (gameData.round.number === 1) {
      if (gamePhase.phase === GamePhase.SelectOptions) {
        return !!gameData.rounds[0][hostData.userConnection]?.gif;
      }
      return false;
    }
    if (gamePhase.phase === GamePhase.SelectTemplate) {
      const templates = Object.values(gameData.rounds[gameData.round.number] || {}).map(
        (userRound) => userRound.template
      );
      return templates.every(Boolean) && templates.length === numberOfAuthors;
    }
    if (gamePhase.phase === GamePhase.SelectOptions) {
      const scenes = Object.values(gameData.rounds[gameData.round.number] || {}).map(
        (userRound) => userRound.scene
      );
      return scenes.every(Boolean) && scenes.length === numberOfAuthors;
    }
  }
);

/**
 * Retrieve status of all audio tracks to initiate preload
 */
export const getPreloadAudio = createSelector([getGameData], (gameData) => {
  return (
    gameData?.rounds.reduce((prev: { [key: string]: boolean }, round) => {
      return {
        ...prev,
        ...Object.keys(round).reduce(
          (prevObj: { [key: string]: boolean }, connectionId: string) => ({
            ...prevObj,
            [round[connectionId]?.audio?.src || ""]: !!round[connectionId]?.audio?.ready,
          }),
          {}
        ),
      };
    }, {}) || {}
  );
});
