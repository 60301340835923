/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";
import { useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import { PlayerMarker } from "../../components/PlayerMarker";
import { getSubmittedStatus } from "../selector/selector";

const mapToState = createSelector([getSubmittedStatus], (submittedStatus) => ({ submittedStatus }));

type Props = { nextScene: () => void } & ReturnType<typeof mapToState>;

const PlayerSubmittedStatusComponent = ({ submittedStatus, nextScene }: Props) => {
  useEffect(() => {
    if (submittedStatus.length > 0) {
      const unsubmitted = submittedStatus.filter((status) => !status.hasSubmitted);
      if (unsubmitted.length === 0) {
        nextScene();
      }
    }
  }, [submittedStatus, nextScene]);

  return (
    <div>
      <Flex
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
          justifyContent: "center",
          "> div": { margin: 3 },
        }}
      >
        {submittedStatus.map(({ name, hasSubmitted, index }) => (
          <PlayerMarker
            key={`marker-${index}`}
            name={`${name} - ${hasSubmitted ? "✓" : "☓"}`}
            colorIndex={index}
          />
        ))}
      </Flex>
    </div>
  );
};

export const PlayerSubmittedStatus = connect(mapToState)(PlayerSubmittedStatusComponent);
