/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";
import { LogOnMount } from "@amplitude/react-amplitude";

import { COMPLETED_API_BASE } from "../../config";
import { updatedAction } from "../../store/lobby/lobbyActions";
import { finalRoundScenes } from "../../game/selector/selector";
import { Logo } from "../../components/TitleLogo";
import { Button } from "../../components/Button";
import { ReviewScenes } from "../../game/phases/reviewScenes";
import { Pages, PageWrapper } from "../pageWrapper";

const mapToState = createSelector([finalRoundScenes], (finalRoundScenes) => ({
  finalRoundScenes,
}));

const dispatchProps = {
  update: updatedAction,
};

type Props = ReturnType<typeof mapToState> & typeof dispatchProps;

const PlaySaved = ({ finalRoundScenes, update }: Props) => {
  const { id } = useParams<{ id: string }>();
  const [pageState, setPageState] = useState<string>();
  const [pageError, setPageError] = useState<string>();
  const [userPlay, setUserPlay] = useState(false);

  useEffect(() => {
    if (id) {
      fetch(`${COMPLETED_API_BASE}/${id}.json`)
        .then((response) => response.json())
        .then((result) => {
          setPageState(result);
          update([result]);
        })
        .catch((err) => {
          setPageError(JSON.stringify(err));
        });
    }
  }, [id, update]);

  return (
    <PageWrapper type={Pages.GAME}>
      <LogOnMount eventType="playPage" />

      {pageState && (
        <Flex
          sx={{
            flexWrap: "wrap",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <div sx={{ marginBottom: 4 }}>
            <Logo smaller />
          </div>

          {userPlay ? (
            finalRoundScenes && (
              <div>
                <LogOnMount
                  eventType="playback"
                  eventProperties={(props: any) => ({
                    ...props,
                    id,
                  })}
                />
                <ReviewScenes
                  roundScenes={finalRoundScenes}
                  isPlayPage
                  shareLink={`${window.location.protocol}//share.${window.location.hostname}/play/${id}`}
                />
              </div>
            )
          ) : (
            <Button
              label="Play Scene"
              onClick={() => {
                setUserPlay(true);
              }}
            />
          )}
        </Flex>
      )}
      {pageError && (
        <div>
          <LogOnMount
            eventType="playback error"
            eventProperties={(props: any) => ({
              ...props,
              pageError,
            })}
          />
          <p sx={{ color: "error" }}>Unable to find gameplay. Check the ID and try again.</p>
        </div>
      )}
    </PageWrapper>
  );
};

export const PlayPage = connect(mapToState, dispatchProps)(PlaySaved);
