/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";

export const FormRow = ({ children }: any) => (
  <Flex
    sx={{
      flexDirection: "row",
      justifyContent: "center",
      paddingTop: 1,
      paddingBottom: 1,
      flexWrap: "wrap",
    }}
  >
    {children}
  </Flex>
);
