/** @jsxImportSource theme-ui */
import { Flex, Themed } from "theme-ui";
import { useState } from "react";
import { Amplitude } from "@amplitude/react-amplitude";

import { Button } from "../../components/Button";
import { CountdownComponent } from "../../components/Countdown";
import { LoadingComponent } from "../../components/LoadingSpinner";

import { PlayScene } from "../components/play-scene";
import { currentRoundScenes } from "../selector/selector";

type Props = {
  roundScenes: ReturnType<typeof currentRoundScenes>;
  phaseDuration: number;
  roomKey: string | null;
  interact: any;
};

export const VoteScenes: React.FC<Props> = ({ roundScenes, phaseDuration, roomKey, interact }) => {
  const [activeScene, setActiveScene] = useState<number | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const duration = phaseDuration;

  return (
    <Amplitude>
      {({ logEvent }: any) => (
        <div>
          <CountdownComponent millisecondDuration={duration} />
          <Themed.h3 sx={{ textAlign: "center" }} data-cy="vote-title">
            Vote for the best conclusion.
          </Themed.h3>
          <Flex sx={{ flexWrap: "wrap", margin: 2, marginBottom: "60px" }}>
            {roundScenes?.map(({ scene, gif, audio }, index) => (
              <a
                href="/"
                sx={{ margin: 2, cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveScene(index);
                  logEvent("preview scene", { previewedScene: scene });
                }}
                data-cy="vote-scene"
              >
                <PlayScene
                  key={gif}
                  gif={gif as any}
                  audio={{ ...audio, ready: index === activeScene } as any}
                  finalString={scene || ""}
                  variation={index === activeScene ? "normal" : "small"}
                />
              </a>
            ))}
          </Flex>

          <div
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
            }}
          >
            <div
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
              }}
            >
              {!submitted ? (
                <Button
                  variation="normal"
                  label="Vote"
                  onClick={() => {
                    const selectedScene = roundScenes?.[activeScene || 0];
                    interact({
                      roomKey,
                      payload: {
                        type: "vote",
                        voteSessionId: selectedScene.sessionId,
                      },
                    });
                    setSubmitted(true);
                    logEvent("vote scene", { votedScene: selectedScene.scene });
                  }}
                />
              ) : (
                <LoadingComponent size={65} />
              )}
            </div>
          </div>
        </div>
      )}
    </Amplitude>
  );
};
