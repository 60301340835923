import { createStore, applyMiddleware, combineReducers } from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";

import { BUILD_WITH_DEVTOOLS } from "../config";
import connectionMiddleware from "./connection/connectionMiddleware";
import connection from "./connection/connectionReducer";

import lobby from "./lobby/lobbyReducer";
import lobbyEpics from "./lobby/lobbyEpic";
import payment from "./payment/paymentReducer";
import paymentEpics from "./payment/paymentEpic";

const epicMiddleware = createEpicMiddleware();
const middleware = [epicMiddleware, connectionMiddleware()] as any;

export const configureStore = (withLogger?: boolean) => {
  const store = createStore(
    combineReducers({ connection, lobby, payment }),
    BUILD_WITH_DEVTOOLS === "true"
      ? composeWithDevTools(
          withLogger ? applyMiddleware(...[...middleware, logger]) : applyMiddleware(...middleware)
        )
      : applyMiddleware(...middleware)
  );
  epicMiddleware.run(combineEpics(lobbyEpics, paymentEpics));

  return { store };
};
