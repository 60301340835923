import initialState from "./lobbyState";
import {
  CREATE,
  CREATED,
  CONNECTION,
  JOIN,
  JOINED,
  UPDATED,
  JOIN_ERROR,
  CLEAR,
} from "./lobbyActions";

const reducer = (state = initialState, action: any) => {
  let roomKey;
  switch (action.type) {
    case CREATE:
    case JOIN:
      return { ...state, myName: action.payload.playerName, error: undefined };
    case CREATED:
      roomKey = action.payload.roomKey;
      return { ...state, roomKey, lastRoomKey: roomKey, isHost: true };
    case CONNECTION:
      return { ...state, connectionId: action.payload.connectionId };
    case JOINED:
      roomKey = action.payload.roomKey;
      return { ...state, roomKey, lastRoomKey: roomKey };
    case JOIN_ERROR:
      return { ...state, error: action.payload.message };
    case UPDATED:
      return { ...state, lobbyData: action.payload };
    case CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
export default reducer;
