import { Auth } from "aws-amplify";
import { AUTH_USERNAME, AUTH_USER_TOKEN_KEY } from "../../config";

export const login = (username: string, password: string): Promise<any> => {
  return Auth.signIn(username.toLocaleLowerCase(), password).then((user) => {
    localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
    localStorage.setItem(AUTH_USERNAME, username);
    return user;
  });
};
