/** @jsxImportSource theme-ui */
import { Flex, Themed } from "theme-ui";
import { useState, useEffect, useRef } from "react";
import copy from "copy-to-clipboard";
import UilShare from "@iconscout/react-unicons/icons/uil-share";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import ReactTooltip from "react-tooltip";

import { LoadingComponent } from "../../components/LoadingSpinner";
import { Button } from "../../components/Button";
import { AudioTimer } from "../../components/Audio/audioTimer";
import { LoadWatchSeconds, WatchSceneSeconds } from "../constants/timing";

import { PlayScene } from "../components/play-scene";
import { currentRoundScenes } from "../selector/selector";

type Props = {
  roundScenes: ReturnType<typeof currentRoundScenes>;
  isPlayPage?: boolean;
  shareLink?: string;
  stageComplete?: () => void;
};

const NARRATION_LENGTH = LoadWatchSeconds * 1000;
const SCENE_DURATION = WatchSceneSeconds * 1000;

export const ReviewScenes: React.FC<Props> = ({
  roundScenes,
  isPlayPage,
  shareLink,
  stageComplete,
}) => {
  const [sceneCount, setSceneCount] = useState<number | null>(null);
  const [runningTimer, setTimer] = useState<any>();
  const lastRef = useRef<any>(null);

  // continue to next stage if no scenes to play
  useEffect(() => {
    if (roundScenes.length === 0 && stageComplete) {
      stageComplete();
    }
  });

  useEffect(() => {
    if (sceneCount === null) {
      setTimer(
        setTimeout(
          () => {
            console.log("narration over");
            setSceneCount(0);
          },
          isPlayPage ? 0 : NARRATION_LENGTH
        )
      );
    }
  }, [sceneCount, isPlayPage, setTimer]);

  useEffect(() => {
    return () => {
      clearTimeout(runningTimer);
    };
  }, [runningTimer]);

  // Scroll to the last element
  useEffect(() => {
    setTimeout(() => {
      if (lastRef && lastRef.current) {
        lastRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 100);
  }, [lastRef, sceneCount]);

  const nextScene = () => {
    if (sceneCount !== null && sceneCount + 1 < (roundScenes?.length || 0)) {
      console.log("increment count", JSON.stringify(roundScenes[sceneCount + 1]));
      setSceneCount(sceneCount + 1);
    }
    if (sceneCount !== null && sceneCount + 1 === roundScenes?.length && stageComplete) {
      console.log("scene complete");
      setSceneCount(sceneCount + 1);
      stageComplete();
    }
  };

  return (
    <Amplitude>
      {({ logEvent }: any) => (
        <div>
          <LogOnMount eventType="review scene" />
          {sceneCount !== null ? (
            roundScenes?.map(
              ({ scene, gif, audio }, index) =>
                index <= sceneCount && (
                  <div key={index} sx={{ marginBottom: 3 }}>
                    <PlayScene key={gif} gif={gif as any} finalString={scene || ""} />
                    {index === sceneCount ? (
                      <div ref={lastRef}>
                        <AudioTimer
                          url={audio?.ready ? audio.src : null}
                          duration={SCENE_DURATION}
                          delay={1500}
                          handleTimeout={nextScene}
                        />
                      </div>
                    ) : null}
                    {index === roundScenes?.length - 1 && shareLink && <Themed.p>The End</Themed.p>}
                  </div>
                )
            )
          ) : (
            <LoadingComponent />
          )}

          <div sx={{ margin: 8 }} />

          {shareLink && (
            <div>
              <Flex sx={{ justifyContent: "center" }}>
                <Button label="Play a game" to="/start" />
                <div>
                  <Button
                    sx={{ backgroundColor: "area", color: "text" }}
                    label={
                      (
                        <Flex sx={{ alignItems: "center" }}>
                          {!isPlayPage && <span sx={{ marginRight: "2px" }}>Share</span>}
                          <UilShare />
                        </Flex>
                      ) as any
                    }
                    onClick={() => {
                      copy(shareLink);
                      logEvent("share scene", { shareLink });
                    }}
                    data-tip="Link copied!"
                    data-cy="share-button"
                  />
                  <ReactTooltip
                    place="top"
                    type="success"
                    event="mouseup"
                    eventOff="blur"
                    effect="float"
                    backgroundColor="#222222"
                    afterShow={() => {
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 1000);
                    }}
                  />
                </div>
              </Flex>
            </div>
          )}

          <div
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "table",
              padding: 3,
            }}
            data-cy="giphy-review-logo"
          >
            <div sx={{ marginBottom: "300px" }}>
              <img
                alt="Powered By Giphy"
                src="/PoweredBy_200_Horizontal_Light-Backgrounds_With_Logo.gif"
              />
            </div>
          </div>
        </div>
      )}
    </Amplitude>
  );
};
