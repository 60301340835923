import { createSelector } from "reselect";
import { AppState } from "../initialState";
import { ConfigData } from "./lobbyState";

export const getRoomKey = (state: AppState) => state.lobby.roomKey;

export const getAuthors = (state: AppState) =>
  state.lobby.lobbyData
    .filter((data) => !!data.name)
    .sort((a, b) => a.ttl - b.ttl)
    .map((data) => data.name);

export const getIsHost = (state: AppState) => state.lobby.isHost;

export const getIsGameStarting = (state: AppState) =>
  !!state.lobby.lobbyData.find((data) => data.userConnection === "DATA");

const configDataSelector = (state: AppState) =>
  state.lobby.lobbyData.find((data) => data.userConnection === "CONFIG");
export const getConfigData = createSelector([configDataSelector], (configData?: ConfigData) => ({
  stageLength: configData?.stageLength || { 0: 1, 1: 3, 2: 4, 3: 4, 4: 4, 5: 1 },
  lastRound: configData?.lastRound || 4,
  voice: configData?.voice || "Brian",
  language: configData?.language || "en-US",
  gifSearchRating: configData?.gifSearchRating || "pg-13",
}));

export const getConnectionId = (state: AppState) => state.lobby.connectionId;

export const getLobbyError = (state: AppState) => state.lobby.error;

export const getLobbyAuthState = (state: AppState) =>
  state.lobby.lobbyData.find((data) => data.authState)?.authState;

export const getLobbyState = createSelector(
  [
    getRoomKey,
    getAuthors,
    getIsHost,
    getLobbyAuthState,
    getIsGameStarting,
    getConnectionId,
    getLobbyError,
  ],
  (roomKey, authors, isHost, lobbyAuthState, isGameStarting, connectionId, lobbyError) => ({
    roomKey,
    authors,
    isHost,
    lobbyAuthState,
    isGameStarting,
    connectionId,
    lobbyError,
  })
);
