/** @jsxImportSource theme-ui */
import React, { FC, useEffect } from "react";
import * as serviceWorker from "../../serviceWorkerRegistration";
import { Button } from "../Button";

export const ShouldUpdate: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    console.log("on update!");
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  return showReload ? (
    <div
      sx={{
        position: "relative",
        minHeight: "100vh",
        width: ["10%", "10%", "10%", "100px"],
      }}
    >
      <div sx={{ position: "fixed", bottom: 4, right: [0, 0, 0, 1], zIndex: 10 }}>
        <Button label="Reload to Update" onClick={() => reloadPage()} />
      </div>
    </div>
  ) : (
    <div></div>
  );
};
