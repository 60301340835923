/** @jsxImportSource theme-ui */

import { RenderManager } from "../../game/renderer/renderManager";
import { Pages, PageWrapper } from "../pageWrapper";

export const GamePage = () => (
  <PageWrapper type={Pages.GAME}>
    <RenderManager />
  </PageWrapper>
);
