export const getEnv = (devVar = "", prodVar?: string, testVar?: string): string => {
  const IS_DEVELOPMENT = process.env.REACT_APP_ENV === "development";
  const IS_TEST = process.env.REACT_APP_ENV === "test";
  if (!prodVar || IS_DEVELOPMENT || !process.env.REACT_APP_ENV) {
    return devVar;
  }
  if (testVar && IS_TEST) {
    return testVar;
  }
  return prodVar;
};

export const ENABLE_FORCE_NEXT = getEnv("true", "false");

export const WEBSOCKET_API = getEnv(
  "wss://1gibf3bya8.execute-api.us-east-1.amazonaws.com/our-story",
  "wss://0m6djlajq8.execute-api.us-east-1.amazonaws.com/our-story"
);
export const AMPLITUDE_KEY = getEnv(
  "a216a1a5f0a2448a4313e75fbb34be08",
  "f8c6d0c67355738d8d85f5db6731e724"
);
export const PROTECTED_API_BASE = getEnv(
  "https://1v4x386dqb.execute-api.us-east-1.amazonaws.com/prod",
  "https://a8d18ahgj4.execute-api.us-east-1.amazonaws.com/prod"
);
export const NARRATION_API_BASE = getEnv(
  "https://our-story-narration.s3.amazonaws.com",
  "https://our-story-narration-prod.s3.amazonaws.com"
);
export const COMPLETED_API_BASE = getEnv(
  "https://our-story-game-complete.s3.amazonaws.com",
  "https://our-story-game-complete-prod.s3.amazonaws.com"
);
export const PAYMENT_API_BASE = getEnv(
  "https://j9jxu4mo85.execute-api.us-east-1.amazonaws.com/prod",
  "https://nctpspunol.execute-api.us-east-1.amazonaws.com/prod"
);
export const STRIPE_KEY = getEnv(
  "pk_test_qoeqET1Tb6RZiHWmdLn6AFx900UmrrcaSm",
  "pk_live_EKvFVcx9Yt05qffgMqTTXgO900UHgQW7aV"
);
export const GIPHY_KEY = "BUggNuOUN5Zpb4iNO7dQp8biSpq2SuRH";

export const BUILD_WITH_DEVTOOLS = getEnv("true", "false");

/** Auth user token key */
export const AUTH_USER_TOKEN_KEY = "ReactAmplify.TokenKey";
export const AUTH_USERNAME = "ReactAmplify.Username";
export const AUTH_PASS = "ReactAmplify.Pass";

// Amplify configuration
export const awsConfig: any = getEnv(
  {
    aws_project_region: "us-east-1",
    // aws_cognito_identity_pool_id: "",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_AvfMfxctk",
    aws_user_pools_web_client_id: "1lifgj7tlif998ru31bj7se4ug",

    oauth: {
      domain: "ourstoryauth.auth.us-east-1.amazoncognito.com",
      scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/login/",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
  } as any,
  {
    aws_project_region: "us-east-1",
    // aws_cognito_identity_pool_id: "",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_GRaBSvDa9",
    aws_user_pools_web_client_id: "7oietneiqhv1bn96nv1d2r2niu",
    oauth: {
      domain: "ourstoryauthprod.auth.us-east-1.amazoncognito.com",
      scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://ourstorygif.com/",
      redirectSignOut: "https://ourstorygif.com/login/",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
  } as any
);
