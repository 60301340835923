/** @jsxImportSource theme-ui */
import { Flex, Themed, ThemeUICSSObject } from "theme-ui";
import { ChangeEvent, FocusEvent, HTMLProps, FC } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const offsetLabelSx: ThemeUICSSObject = {
  marginTop: -5,
  fontSize: 0,
};

const inputSx = (hasValue: boolean): ThemeUICSSObject => ({
  borderColor: "error",
  borderBottom: "1px solid",
  borderTop: 0,
  borderRight: 0,
  borderLeft: 0,
  backgroundColor: "transparent",
  padding: 2,
  fontSize: [2, 3, 3],
  color: "text",
  transition: "300ms ease all",
  ":focus": {
    outline: "none",
    borderColor: "focus",
  },
  ":focus ~ label": { ...offsetLabelSx, color: "focus" },
  "& ~ label": hasValue ? offsetLabelSx : {},
});

export type TextFieldProps = {
  name: string;
  label: string;
  value?: string;
  onChange?: (event: ChangeEvent) => void;
  onBlur?: (event: FocusEvent) => void;
  error?: string;
  warning?: string;
  variation?: "small" | "normal";
} & HTMLProps<HTMLInputElement>;

export const TextField: FC<TextFieldProps> = ({
  name,
  label,
  value = "",
  onChange = noop,
  onBlur = noop,
  error,
  warning,
  variation = "normal",
  ...rest
}) => (
  <Flex
    sx={{
      flexDirection: "column",
      textAlign: "left",
      maxWidth: variation === "normal" ? 600 : "50%",
      marginTop: 5,
      marginBottom: 5,
    }}
  >
    <input
      sx={inputSx(value !== "")}
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
    />
    <Themed.p
      as="label"
      sx={{
        position: "absolute",
        paddingLeft: 2,
        paddingTop: 3,
        marginBottom: 0,
        fontSize: [1, 2, 2],
        pointerEvents: "none",
        color: "secondary",
        transition: "300ms ease all",
      }}
    >
      {label}
    </Themed.p>
    {(error || warning) && (
      <p
        sx={{
          color: error ? "error" : "accent",
          fontSize: [0, 1, 1],
          marginTop: 1,
          marginBottom: -6,
          paddingLeft: 2,
          paddingBottom: "2px", // TODO: improve to not need this
        }}
      >
        {error ? error : warning}
      </p>
    )}
  </Flex>
);
