/** @jsxImportSource theme-ui */

import { useContext, useState, useEffect } from "react";

import { AudioContext } from "../../context/audioContext";
import { useAudio } from ".";

type AudioTimerProps = {
  url: string | null; // URL of the Audio
  duration: number; // time to wait not factoring in Audio length
  delay?: number; // time delay after audio played to return timeout callback
  handleTimeout: () => void; // Callback function for audio timer complete
};

/**
 * AudioTimer manages a timer that adjusts itself to guarantee that it
 * encompasses the full duration of the audio in it's length
 *
 * If audio url is loaded late, it will respond by cancelling the running
 * timer and listening instead to the time when the audio has completed.
 *
 * @param { AudioTimerProps } props;
 */
export const AudioTimer = ({ url, duration, delay, handleTimeout }: AudioTimerProps) => {
  const { context, volume } = useContext(AudioContext);
  const [isPlaying] = useAudio(url || "", context, false, volume);

  const [playing, setPlaying] = useState(false);
  const [defaultTimer, setTimer] = useState<any>(null);

  // setup base default timer used when no audio timer created
  useEffect(() => {
    if (!defaultTimer) {
      setTimer(
        setTimeout(() => {
          handleTimeout();
        }, duration)
      );
    }
  }, [defaultTimer, duration, setTimer, handleTimeout]);

  // Watch playing and respond to changes in audio playback
  useEffect(() => {
    if (isPlaying && !playing) {
      setPlaying(true);
      clearTimeout(defaultTimer);
    }
    if (!isPlaying && playing) {
      if (delay) {
        setTimeout(() => {
          handleTimeout();
        }, delay);
      } else {
        handleTimeout();
      }
    }
  }, [isPlaying, playing, defaultTimer, delay, handleTimeout]);

  // Destruction removes handlers
  useEffect(() => {
    return () => {
      clearTimeout(defaultTimer);
    };
  }, [defaultTimer]);

  return <div></div>;
};
