/** @jsxImportSource theme-ui */
import { Themed } from "theme-ui";
import { useState } from "react";
import { Amplitude } from "@amplitude/react-amplitude";

import { ACTION_RANDOMS, NAME_RANDOMS, PLACE_RANDOMS } from "../constants/randomOptions";
import { CountdownComponent } from "../../components/Countdown";
import { OptionInputForm } from "../../components/OptionInputForm/option-input-form";

type Props = {
  phaseDuration: number;
  round?: number;
  submitValue: (type: string, value: string) => void;
};

export const InputOptions = ({ phaseDuration, round, submitValue }: Props) => {
  const [active, setActive] = useState<string>(round === 1 ? "name" : "place");
  const duration = phaseDuration;

  const onTick = (seconds: number) => {
    const durationSeconds = phaseDuration / 1000;
    let result;
    if (round === 1) {
      result = seconds / (durationSeconds / 3);
    } else {
      result = seconds / (durationSeconds / 2);
    }

    if (result > 2) {
      setActive("name");
    } else if (result > 1) {
      setActive("place");
    } else {
      setActive("action");
    }
  };

  return (
    <Amplitude>
      {({ logEvent }: any) => (
        <div>
          <CountdownComponent
            millisecondDuration={duration}
            modLength={round === 1 ? duration / 1000 / 3 : duration / 1000 / 2}
            onTick={onTick}
          />

          <Themed.h4 data-cy="input-options-title">Add details for everyone to use</Themed.h4>

          <Themed.h4>Add as many as you can before the time runs out!</Themed.h4>

          {round === 1 && (
            <OptionInputForm
              label="name"
              randomOptions={NAME_RANDOMS}
              isActive={active === "name"}
              isAction={false}
              onSubmit={(value: string) => {
                submitValue("name", value);
                logEvent("add-name", { addName: value });
              }}
            />
          )}
          <OptionInputForm
            label="place"
            randomOptions={PLACE_RANDOMS}
            isActive={active === "place"}
            isAction={false}
            onSubmit={(value: string) => {
              submitValue("place", value);
              logEvent("add-place", { addPlace: value });
            }}
          />
          <OptionInputForm
            label="action"
            randomOptions={ACTION_RANDOMS}
            isActive={active === "action"}
            isAction={true}
            onSubmit={(value: string) => {
              submitValue("action", value);
              logEvent("add-action", { addAction: value });
            }}
          />
        </div>
      )}
    </Amplitude>
  );
};
