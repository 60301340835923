/** @jsxImportSource theme-ui */
import { Themed } from "theme-ui";
import { useEffect, useState } from "react";

import { Button } from "../../components/Button";
import { WordSelect } from "./word-select";

type Props = {
  template: string;
  names: string[];
  actions: string[];
  places: string[];
  // todo: update to expose selected
  selectString: (
    parts: string[],
    selected: { action: string[]; place: string[] },
    selectedAction: string
  ) => void;
};

export const PickOptions = ({ template, names, actions, places, selectString }: Props) => {
  const [selectedAction, setAction] = useState(actions[0]);
  const [selectedParts, setSelectedParts] = useState(
    template.split(/\{|\}/).map((part) => {
      switch (part) {
        case "name":
          return names[0];
        case "action":
          return actions[0];
        case "place":
          return places[0];
        default:
          return part;
      }
    })
  );

  const [selected, setSelected] = useState<{ [key: string]: string[] }>({ action: [], place: [] });
  useEffect(() => {
    if (selectedParts) {
      const selection: { actions: string[]; places: string[] } = { actions: [], places: [] };
      selectedParts.forEach((part: string) => {
        if (actions.includes(part)) {
          selection.actions.push(part);
        }
        if (places.includes(part)) {
          selection.places.push(part);
        }
      });
      setSelected(selection);
    }
  }, [actions, places, selectedParts, setSelected]);

  return (
    <div
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: [300, 400, 600],
      }}
    >
      <div sx={{ backgroundColor: "area", padding: 3 }}>
        {template.split(/\{|\}/).map((part, i) => {
          const onSelect = (value: string) => {
            const values = [...selectedParts];
            values[i] = value;
            setSelectedParts(values);
            if (part === "action") {
              setAction(value);
            }
          };
          switch (part) {
            case "name":
              return <WordSelect key={i} words={names} onSelect={onSelect} />;
            case "action":
              return <WordSelect key={i} words={actions} onSelect={onSelect} />;
            case "place":
              return <WordSelect key={i} words={places} onSelect={onSelect} />;
            default:
              return (
                <Themed.h3 sx={{ textAlign: "center" }} key={i}>
                  {part}
                </Themed.h3>
              );
          }
        })}
      </div>
      <Button
        sx={{ margin: 3 }}
        onClick={() => {
          selectString(
            template
              .split(/\{|\}/)
              .map((part, i) =>
                ["name", "action", "place"].includes(part) ? selectedParts[i] : part
              ),
            selected as any,
            selectedAction
          );
        }}
        label="Select"
        data-cy="options-select-button"
      />
    </div>
  );
};
