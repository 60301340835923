export const NAME_RANDOMS = [
  "Silly Steve",
  "Big Mike",
  "Sammy",
  "Fred",
  "Benji",
  "Ralph",
  "Della",
  "Miguel",
];

export const PLACE_RANDOMS = [
  "the Pacific Ocean",
  "chilly Toronto",
  "under the covers",
  "the Alps",
  "the sewers",
  "a dumpster",
  "a hot tub",
];

export const ACTION_RANDOMS = [
  "snorted while laughing",
  "ate all the cheese",
  "sang like an angel",
  "tiptoed loudly",
  "ran into a wall",
  "did a bunch of pushups",
];

export const LOBBY_TITLES = [
  "Foreword by ",
  "With motivation from ",
  "Written with ",
  "Contributions from Prof. ",
  "Aside provided by ",
  "Imagery from ",
  "Edited with guidance from ",
  "Heavily critiqued by ",
];
