/** @jsxImportSource theme-ui */
import { Themed } from "theme-ui";
import { useContext } from "react";
import { createSelector } from "reselect";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { LogOnMount } from "@amplitude/react-amplitude";

import { ENABLE_FORCE_NEXT, NARRATION_API_BASE } from "../../config";
import { getConnectionState } from "../../store/connection/connectionSelector";
import { getConfigData, getLobbyState } from "../../store/lobby/lobbySelector";
import { configureAction, interactAction } from "../../store/lobby/lobbyActions";
import { AudioContext } from "../../context/audioContext";
import { Logo } from "../../components/TitleLogo";
import { useAudio } from "../../components/Audio";
import { Button } from "../../components/Button";
import { Pages, PageWrapper } from "../pageWrapper";
import { LobbyInviteSection } from "./lobbyInviteSection";
import { AuthorDisplay } from "./lobbyAuthors";
import { Configuration } from "../../game/components/configuration";

const mapToState = createSelector(
  [getConnectionState, getLobbyState, getConfigData],
  (connectionState, lobbyState, configData) => ({
    isConnected: connectionState.isConnected,
    roomKey: lobbyState.roomKey,
    authors: lobbyState.authors,
    isHost: lobbyState.isHost,
    lobbyAuthState: lobbyState.lobbyAuthState,
    isGameStarting: lobbyState.isGameStarting,
    configData,
  })
);

const dispatchProps = {
  interact: interactAction,
  configure: configureAction,
};

type Props = ReturnType<typeof mapToState> & typeof dispatchProps;

const OptionLobby = ({
  isConnected,
  roomKey,
  authors,
  isHost,
  lobbyAuthState,
  isGameStarting,
  configData,
  interact,
  configure,
}: Props) => {
  const { context, volume } = useContext(AudioContext);
  const isGameFull = lobbyAuthState?.isAuthenticated ? authors.length >= 4 : authors.length >= 2;

  useAudio(
    `${NARRATION_API_BASE}/${configData.voice}-${configData.language}-welcome.mp3`,
    context,
    false,
    volume
  );

  return (
    <PageWrapper type={Pages.LOBBY}>
      <LogOnMount eventType="lobbyPage" />
      {!isConnected && (
        <div>
          <LogOnMount eventType="not connected" />
          <Redirect to="/" />
        </div>
      )}
      {isGameStarting && (
        <div>
          <LogOnMount
            eventType="launch game"
            eventProperties={(props: any) => ({
              ...props,
              isHost,
              authors,
            })}
          />
          <Redirect to="/game" />
        </div>
      )}

      <div sx={{ minHeight: "30vh" }}>
        <Logo />

        {/* Invitation Logic */}
        {!isGameFull ? (
          <div>
            <Themed.h4 data-cy="lobby-title">
              {isHost ? "Game Created" : "Gathering authors..."}
            </Themed.h4>

            <LobbyInviteSection
              hint={
                <div>
                  <Themed.p sx={{ paddingTop: 3, marginBottom: 0 }}>Invite Players</Themed.p>
                  {authors.length === 1 && (
                    <p sx={{ marginBottom: 0 }}>You need at least 2 player to start a game</p>
                  )}
                </div>
              }
              roomKey={roomKey}
            />
          </div>
        ) : isHost ? (
          lobbyAuthState?.isAuthenticated ? (
            <p sx={{ marginBottom: 0, paddingTop: 3 }}>
              Game is full. Upgrade account to invite more players.
            </p>
          ) : (
            <p sx={{ marginBottom: 0, paddingTop: 3 }}>
              Game is full. Sign up for a free invite increase
            </p>
          )
        ) : (
          <p sx={{ marginBottom: 0, paddingTop: 3 }}>Game is full. Ready to start.</p>
        )}

        {context.state !== "running" && <p>Audio needs to be enabled to host a game</p>}
      </div>

      {isHost && roomKey && <Configuration onSubmit={(result) => configure(roomKey, result)} />}

      {((ENABLE_FORCE_NEXT === "true" && isHost) ||
        (isHost && authors.length > 1 && context.state === "running")) && (
        <Button
          sx={{
            margin: 3,
            backgroundColor: "text",
          }}
          type="button"
          label="Start"
          data-cy="start-button"
          onClick={() => {
            interact({
              roomKey,
              payload: {
                type: "start",
                theme: "space-western",
                voice: configData.voice,
                language: configData.language,
              },
            });
          }}
        />
      )}

      <AuthorDisplay authors={authors} />
    </PageWrapper>
  );
};

export const LobbyPage = connect(mapToState, dispatchProps)(OptionLobby);
