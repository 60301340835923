/** @jsxImportSource theme-ui */

import { Link } from "react-router-dom";
import { useResponsiveValue } from "@theme-ui/match-media";

import logo500 from "./logo-500.png";
import logo300 from "./logo-300.png";
import logo200 from "./logo-200.png";

export const TitleLogo = () => (
  <div sx={{ width: "auto", marginTop: 4 }}>
    <Link to="/">
      <img
        sx={{
          filter: "drop-shadow(5px 5px 5px #222)",
          height: `${useResponsiveValue([200, 200, 250])}px`,
          width: `${useResponsiveValue([200, 200, 250])}px`,
        }}
        src={useResponsiveValue([logo300, logo300, logo500])}
        width={useResponsiveValue([200, 200, 250])}
        alt="Our Story Logo"
      />
    </Link>
  </div>
);

type LogoProps = { smaller?: boolean };
export const Logo = ({ smaller }: LogoProps) => (
  <div sx={{ padding: 3 }}>
    <a href="/">
      <img
        sx={{
          filter: "drop-shadow(5px 5px 5px #222)",
          height: `${smaller ? 80 : 100}px`,
          width: `${smaller ? 80 : 100}px`,
        }}
        src={logo200}
        width={smaller ? 80 : 100}
        alt="Our Story Logo"
      />
    </a>
  </div>
);
