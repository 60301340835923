/** @jsxImportSource theme-ui */
import { Themed, ThemeUICSSObject } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";
import { useCallback, useEffect, useRef, useState } from "react";
import { Gif } from "@giphy/react-components";
import { IGif } from "@giphy/js-types";
import { GiphyFetch } from "@giphy/js-fetch-api";

import { AudioComponent } from "../../components/Audio";
import { GIPHY_KEY } from "../../config";

type Props = {
  gif: string;
  finalString: string;
  audio?: {
    src: string;
    ready: boolean;
  };
  variation?: "normal" | "small";
};

const fetch = new GiphyFetch(GIPHY_KEY);
export const borderCardStyle = (isSmall: boolean): ThemeUICSSObject => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: isSmall ? "300px" : "500px",
  backgroundColor: "area",
  paddingTop: "10px",
  paddingBottom: "20px",
});

export const PlayScene = ({ gif, finalString, audio, variation = "normal" }: Props) => {
  const [iGif, setIGif] = useState<IGif>((null as unknown) as IGif);
  const [shownString, setShownString] = useState("");
  const smallScale = useResponsiveValue([true, false]);

  const i = useRef(0);
  const typeWriter = useCallback(() => {
    if (i.current < finalString.length) {
      setShownString(finalString.slice(0, i.current + 1));
      i.current += 1;
      setTimeout(typeWriter, 20);
    }
  }, [finalString]);

  useEffect(() => {
    if (gif) fetch.gif(gif).then((result) => setIGif(result.data));
    typeWriter();
  }, [gif, typeWriter]);

  const Heading = variation === "normal" ? Themed.h3 : Themed.h4;

  return (
    <div sx={borderCardStyle(variation === "small")} data-cy="play-scene">
      <Heading
        sx={{ textAlign: "left", paddingX: [3, 3, 4], width: "100%", minHeight: [81, 81, 108] }}
      >
        {shownString}
      </Heading>
      {iGif && (
        <Gif
          gif={iGif}
          width={variation === "normal" ? (smallScale ? 250 : 350) : smallScale ? 150 : 250}
          hideAttribution
          onGifClick={(gif, e) => e.preventDefault()}
        />
      )}
      {audio?.ready && <AudioComponent url={audio.src} />}
    </div>
  );
};
