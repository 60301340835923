/** @jsxImportSource theme-ui */
import { Flex, Themed } from "theme-ui";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import { Formik } from "formik";
import { LogOnMount } from "@amplitude/react-amplitude";

import { getConnectionState } from "../../store/connection/connectionSelector";
import { initializeAction } from "../../store/connection/connectionActions";
import { getLobbyState } from "../../store/lobby/lobbySelector";
import { createAction, getConnectionAction, clearAction } from "../../store/lobby/lobbyActions";
import { Logo } from "../../components/TitleLogo";
import { Button } from "../../components/Button";
import { TextField } from "../../components/TextField";
import { FormRow } from "../../components/FormRow";
import { Pages, PageWrapper } from "../pageWrapper";
import { LoadingComponent } from "../../components/LoadingSpinner";
import { getLastPlayerName, setLastPlayerName } from "../../util/playername";
import { Auth } from "aws-amplify";
import { AUTH_USERNAME, AUTH_USER_TOKEN_KEY } from "../../config";
import { LogoutButton } from "../../components/LogoutButton/logout";
import { isPaidUser } from "../../util/auth";

const mapToState = createSelector(
  [getConnectionState, getLobbyState],
  (connectionState, lobbyState) => ({
    isConnected: connectionState.isConnected,
    isConnectionError: connectionState.connectionErr !== null,
    roomKey: lobbyState.roomKey,
    connectionId: lobbyState.connectionId,
  })
);

const dispatchProps = {
  getConnectionAction,
  createAction,
  connect: initializeAction,
  clearAction,
};

type Props = ReturnType<typeof mapToState> & typeof dispatchProps;

export const OptionStart: React.FC<Props> = ({
  isConnected,
  isConnectionError,
  connectionId,
  roomKey,
  createAction,
  getConnectionAction,
  clearAction,
  connect,
}) => {
  const [error, setError] = useState<string | null>(null);

  const username = localStorage.getItem(AUTH_USERNAME);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [hasPaid, setPaid] = useState(false);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        // Align Google Login with Email Login
        if (!username) {
          localStorage.setItem(AUTH_USERNAME, session.getIdToken().payload.email || "");
        }
        const token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
        if (!token) {
          localStorage.setItem(AUTH_USER_TOKEN_KEY, session.getAccessToken().getJwtToken());
        }

        setLoggedIn(true);
      })
      .catch(() => {
        /* noop */
      });
  });

  useEffect(() => {
    isPaidUser().then((isPaid) => {
      setPaid(isPaid);
    });
  }, [setPaid]);

  useEffect(() => {
    connect();
  }, [connect]);

  // Remove state if a previous game exists
  useEffect(() => {
    if (!isConnected && roomKey) {
      clearAction();
    }
  }, [isConnected, roomKey]);

  useEffect(() => {
    if (isConnected) {
      getConnectionAction();
    }
  }, [isConnected, getConnectionAction]);

  return (
    <PageWrapper type={Pages.LOBBY}>
      <LogOnMount eventType="startPage" />
      {roomKey && <Redirect to="/lobby" />}

      <Logo />

      <Themed.h4>Create a new story</Themed.h4>

      <Formik
        initialValues={{ name: getLastPlayerName() }}
        validate={(values) => {
          const errors: any = {};
          if (!values.name) {
            errors.name = "Required to have name";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          createAction(values.name);
          setLastPlayerName(values.name);

          setSubmitting(true);
          setError(null);
        }}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form
            sx={{
              maxWidth: 600,
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 5,
              marginBottom: isLoggedIn ? 300 : 100,
            }}
            onSubmit={handleSubmit}
          >
            <FormRow>
              <TextField
                name="name"
                label="Your Name"
                min="1"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name}
                data-cy="player-name-input"
              />
            </FormRow>

            <Flex sx={{ justifyContent: "center" }}>
              {isSubmitting ? (
                <LoadingComponent isDarkTheme />
              ) : (
                <Button
                  sx={{ margin: 3, fontSize: 3, backgroundColor: "text" }}
                  type="submit"
                  disabled={isSubmitting || !isConnected || !connectionId}
                  label={"Create Game"}
                  data-cy="submit-button"
                />
              )}
              <Button
                sx={{ margin: 3, fontSize: 3, backgroundColor: "secondary" }}
                type="button"
                to="/"
                label="Cancel"
              />
            </Flex>

            {!isConnected && !isConnectionError && (
              <p sx={{ position: "absolute", left: "50%", right: "50%" }}>Connecting...</p>
            )}

            {isConnectionError && (
              <p sx={{ color: "error" }}>Connection erred. Please refresh and try again</p>
            )}
          </form>
        )}
      </Formik>

      {!isLoggedIn && (
        <div>
          <Button type="button" to="/login?path=start" label="Login" data-cy="login-button" />
          <Themed.p>Current limit is 2 players. Sign up for a free player count increase.</Themed.p>
        </div>
      )}

      <LogoutButton>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "flex-end",
            marginBottom: 8,
          }}
        >
          {!hasPaid && (
            <Themed.p sx={{ fontSize: 0, marginBottom: 1 }}>
              Current limit is 4 players. Upgrade to unlock larger games.
            </Themed.p>
          )}

          <div sx={{ margin: 4 }}>
            <Button
              sx={{ backgroundColor: "text" }}
              type="button"
              to="/account"
              label={hasPaid ? "Account" : "Upgrade"}
              variation="small"
            />
          </div>
        </Flex>
      </LogoutButton>

      {error && (
        <div>
          <LogOnMount
            eventType="create room error"
            eventProperties={(props: any) => ({
              ...props,
              error,
            })}
          />
          <Themed.p sx={{ color: "error" }}>
            Error creating new game. Please reload and try again
          </Themed.p>
        </div>
      )}
    </PageWrapper>
  );
};

export const StartPage = connect(mapToState, dispatchProps)(OptionStart);
