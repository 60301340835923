/** @jsxImportSource theme-ui */
import { useState } from "react";
import { Flex, Themed, ThemeUICSSObject } from "theme-ui";
import { Button } from "../../components/Button";
import { OptionInputForm } from "../../components/OptionInputForm/option-input-form";
import { PickGif } from "../../game/components/pick-gif";
import { PickOptions } from "../../game/components/pick-options";
import { PlayScene } from "../../game/components/play-scene";
import { ACTION_RANDOMS, NAME_RANDOMS, PLACE_RANDOMS } from "../../game/constants/randomOptions";
import { TemplateDisplay } from "../../game/phases/pickTemplate";

const wordSpacing: ThemeUICSSObject = {
  maxWidth: "600px",
  marginLeft: "auto",
  marginRight: "auto",
};

export const Demo = () => {
  // configs
  const template = "{name} {action} as fast as they could to {place}";

  const steps = ["template", "entries", "scene", "gif", "review"];
  const [step, setStep] = useState("template");
  const nextStep = () =>
    setStep(steps.length === steps.indexOf(step) + 1 ? steps[0] : steps[steps.indexOf(step) + 1]);
  const prev = () => setStep(steps.indexOf(step) === 0 ? steps[4] : steps[steps.indexOf(step) - 1]);

  const [actions, setActions] = useState<Array<string>>([]);
  const addAction = (action: string) => setActions([...actions, action]);

  const [scene, setScene] = useState("Mark danced as fast as they could to the edge of the world");
  const [selectedAction, setSelectedAction] = useState("danced");

  const [gif, setGif] = useState("s2sFWmnnzDiww");

  return (
    <div>
      <div>
        {step === "template" && (
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Themed.p sx={{ ...wordSpacing }}>Scene templates are picked each round</Themed.p>
            <TemplateDisplay templates={[template]} selectedIndex={0} />
          </Flex>
        )}

        {step === "entries" && (
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Themed.p sx={{ ...wordSpacing }}>
              Players add places, names and actions to fill in scenes
            </Themed.p>
            <div sx={{ backgroundColor: "area" }}>
              <OptionInputForm
                label="action"
                isActive={true}
                isAction={true}
                onSubmit={addAction}
                randomOptions={ACTION_RANDOMS}
              />
            </div>
          </Flex>
        )}

        {step === "scene" && (
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Themed.p sx={{ ...wordSpacing }}>
              Each player uses other player's words to make a scene
            </Themed.p>
            <div sx={{ backgroundColor: "area" }}>
              <PickOptions
                template={template}
                names={NAME_RANDOMS}
                places={PLACE_RANDOMS}
                actions={[...actions, ...ACTION_RANDOMS]}
                selectString={(parts, _, selectedAction) => {
                  setScene(parts.join(" "));
                  setSelectedAction(selectedAction);
                  nextStep();
                }}
              />
            </div>
          </Flex>
        )}

        {step === "gif" && (
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Themed.p sx={{ ...wordSpacing }}>
              After selecting a scene, adding a GIF helps portray the action
            </Themed.p>
            <PickGif
              selectedAction={selectedAction}
              finalScene={scene}
              selectGif={(gif) => {
                setGif(gif.id.toString());
                nextStep();
              }}
            />
          </Flex>
        )}

        {step === "review" && (
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Themed.p sx={{ ...wordSpacing }}>
              At the end of each round all players get to watch the scenes everyone created
            </Themed.p>
            <PlayScene gif={gif} finalString={scene} />
          </Flex>
        )}
      </div>

      <Flex sx={{ justifyContent: "space-around" }}>
        {step === "template" ? <br /> : <Button label="prev" onClick={prev} />}
        <Button label={step === "review" ? "restart" : "next"} onClick={nextStep} />
      </Flex>
    </div>
  );
};
