import { AnyAction, Dispatch, Store as ReduxStore } from "redux";
import { AppState } from "../initialState";
import { WEBSOCKET_API } from "../../config";
import {
  INITIALIZE,
  DISCONNECT,
  SEND,
  connectedAction,
  disconnectedAction,
  connectionErrAction,
} from "./connectionActions";
import {
  createdAction,
  connectionAction,
  updatedAction,
  joinedAction,
  joinErrorAction,
  interactAction,
} from "../lobby/lobbyActions";

enum WebsocketType {
  Created = "created",
  Connected = "connected",
  Joined = "joined",
  UpdatedRoom = "updateroom",
  Interact = "interact",
  Error = "error",
}

type Store = ReduxStore<AppState>;

const connectionMiddleware = () => {
  let socket: null | WebSocket = null;

  const onOpen = (store: Store) => () => {
    store.dispatch(connectedAction());
  };

  const onClose = (store: Store) => () => {
    store.dispatch(disconnectedAction());
  };

  const onMessage = (store: Store) => (event: MessageEvent) => {
    const payload = JSON.parse(event.data);

    // switch payload.type
    switch (payload.type) {
      case WebsocketType.Created:
        store.dispatch(createdAction(payload.roomKey));
        break;
      case WebsocketType.Connected:
        store.dispatch(connectionAction(payload.message));
        break;
      case WebsocketType.Joined:
        store.dispatch(joinedAction(payload.roomKey));
        break;
      case WebsocketType.UpdatedRoom:
        store.dispatch(updatedAction(payload.items));
        break;
      case WebsocketType.Interact:
        store.dispatch(interactAction(payload.request));
        break;
      case WebsocketType.Error:
        store.dispatch(joinErrorAction(payload));
        break;
      default:
        console.log("uncaught message", payload);
        break;
    }
  };

  const onError = (store: Store) => (event: Event) => {
    store.dispatch(connectionErrAction(event));
  };

  return (store: Store) => (next: Dispatch<AnyAction>) => (action: any) => {
    switch (action.type) {
      case INITIALIZE:
        if (socket !== null) {
          socket.close();
        }

        console.info("connecting");
        socket = new WebSocket(WEBSOCKET_API);

        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);
        socket.onerror = onError(store);
        break;
      case DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        console.info("websocket closed");
        break;
      case SEND:
        if (socket) {
          const reqBody = JSON.stringify(action.payload);
          if (socket.CONNECTING === socket.readyState) {
            console.info(`websocket connecting. Unable to send payload ${reqBody}`);
          } else {
            socket.send(reqBody);
          }
        }
        break;
      default:
        return next(action);
    }
  };
};

export default connectionMiddleware;
