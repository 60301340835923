/** @jsxImportSource theme-ui */
import { Themed } from "theme-ui";
import { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";

import { Button, ButtonLabelStyle } from "../../components/Button";
import { FormRow } from "../../components/FormRow";
import { TextField } from "../../components/TextField";
import { AuthWrapper } from "./wrapper";
import { login } from "./utils";
import { Auth } from "aws-amplify";
import { AUTH_USERNAME, AUTH_USER_TOKEN_KEY } from "../../config";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
});

export const Login = () => {
  const [error, setError] = useState<string>();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>();

  useEffect(() => {
    localStorage.removeItem(AUTH_USERNAME);
    localStorage.removeItem(AUTH_USER_TOKEN_KEY);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setRedirectUrl(params.get("redirect"));
  }, [setRedirectUrl]);

  return (
    <AuthWrapper>
      {isLoggedIn ? (
        <div>
          <LogOnMount eventType="login" />
          <Redirect to={{ pathname: redirectUrl || "/" }} />
        </div>
      ) : (
        <Amplitude>
          {({ logEvent }: any) => (
            <div>
              <Themed.h4>Login to Our Story</Themed.h4>
              <LogOnMount eventType="loginPage" />
              <Formik
                initialValues={{ username: "", password: "" }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  logEvent("login attempt");
                  login(values.username, values.password)
                    .then(() => setLoggedIn(true))
                    .catch((err) => {
                      console.log("error", err);
                      setError(err.message);
                    });
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <FormRow>
                      <TextField
                        name="username"
                        label="Email"
                        autoComplete="username"
                        required
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.username ? errors.username : ""}
                      />
                    </FormRow>

                    <FormRow>
                      <TextField
                        name="password"
                        label="Password"
                        autoComplete="current-password"
                        type="password"
                        required
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.password ? errors.password : ""}
                      />
                    </FormRow>

                    <Button
                      sx={{ margin: 3, fontSize: 3, backgroundColor: "text" }}
                      type="submit"
                      label="Login"
                    />

                    <div>
                      <Button
                        sx={{ margin: 3, fontSize: 3, backgroundColor: "primary" }}
                        type="button"
                        to="/signup"
                        label="Sign Up"
                        data-cy="sign-up"
                      />
                      <Button
                        sx={{ margin: 3, fontSize: 3, backgroundColor: "white" }}
                        type="button"
                        onClick={() => Auth.federatedSignIn({ provider: "Google" as any })}
                        label={
                          <div>
                            <svg
                              viewBox="0 0 18 18"
                              width="25"
                              height="25"
                              scale="2"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="#000" fill-rule="evenodd">
                                <path
                                  d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                                  fill="#EA4335"
                                ></path>
                                <path
                                  d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                                  fill="#4285F4"
                                ></path>
                                <path
                                  d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                                  fill="#FBBC05"
                                ></path>
                                <path
                                  d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                                  fill="#34A853"
                                ></path>
                                <path fill="none" d="M0 0h18v18H0z"></path>
                              </g>
                            </svg>
                            <span
                              sx={{
                                ...ButtonLabelStyle,
                                marginLeft: 4,
                                marginRight: 1,
                              }}
                            >
                              Sign In
                            </span>
                          </div>
                        }
                      />
                    </div>

                    <Link sx={{ textDecoration: "none" }} to="/forgot-password">
                      <Themed.h4 sx={{ color: "primary" }}>Forgot password?</Themed.h4>
                    </Link>

                    {error && (
                      <p
                        sx={{
                          color: "error",
                          fontSize: [1, 2, 2],
                        }}
                      >
                        {error}
                      </p>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          )}
        </Amplitude>
      )}
    </AuthWrapper>
  );
};
