/** @jsxImportSource theme-ui */
import { Flex, Container, Themed } from "theme-ui";
import { useState } from "react";
import { Amplitude } from "@amplitude/react-amplitude";

import { Button } from "../../components/Button";
import { CountdownComponent } from "../../components/Countdown";
import { LoadingComponent } from "../../components/LoadingSpinner";

type DisplayProps = { templates: string[]; selectedIndex: number };
export const TemplateDisplay = ({ templates, selectedIndex }: DisplayProps) => (
  <Container
    sx={{
      margin: 2,
      textAlign: "center",
      backgroundColor: "area",
      maxWidth: ["auto", 500, 600],
    }}
  >
    <Themed.h3 sx={{ paddingTop: 2, paddingBottom: 2, lineHeight: 2.5 }}>
      {templates[selectedIndex].split(/\{|\}/).map((part, i) =>
        mapping[part] ? (
          <span
            sx={{
              borderColor: "text",
              borderWidth: 1,
              borderStyle: "dashed",
              padding: 2,
            }}
            key={i}
          >
            {mapping[part]}
          </span>
        ) : (
          <span key={i}>{part}</span>
        )
      )}
    </Themed.h3>
  </Container>
);

// template: "Once upon a time {name} {action} without pants."
// types of things: name | action | place
type Props = {
  templates: string[];
  phaseDuration: number;
  selectTemplate: (template: string) => void;
};

const mapping: { [key: string]: string } = {
  name: "character",
  action: "action",
  place: "place",
};

export const TemplatePick = ({ templates, phaseDuration, selectTemplate }: Props) => {
  const [selectedIndex, selectIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const duration = phaseDuration;

  return (
    <Amplitude>
      {({ logEvent }: any) => (
        <div>
          <CountdownComponent millisecondDuration={duration} />

          {submitted ? (
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>
                Please wait while other players add words for you to use in the template you've
                selected.
              </p>
              <TemplateDisplay templates={templates} selectedIndex={selectedIndex} />
              <LoadingComponent />
            </Flex>
          ) : (
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Themed.h4 data-cy="template-pick-title">Select a scene for the story</Themed.h4>
              <Flex
                sx={{
                  flexDirection: "row",
                }}
              >
                <Button
                  sx={{ margin: [3, 4] }}
                  disabled={selectedIndex === 0}
                  onClick={() => {
                    selectIndex(selectedIndex - 1);
                    logEvent("template:search-left");
                  }}
                  label="<"
                />
                <Button
                  onClick={() => {
                    selectTemplate(templates[selectedIndex]);
                    setSubmitted(true);
                    logEvent("selected template", { template: templates[selectedIndex] });
                  }}
                  label="Select"
                  data-cy="template-select-button"
                />
                <Button
                  sx={{ margin: [3, 4] }}
                  disabled={selectedIndex === templates.length - 1}
                  onClick={() => {
                    selectIndex(selectedIndex + 1);
                    logEvent("template:search-right");
                  }}
                  label=">"
                />
              </Flex>
              <TemplateDisplay templates={templates} selectedIndex={selectedIndex} />
            </Flex>
          )}
        </div>
      )}
    </Amplitude>
  );
};
