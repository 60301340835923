import initialState from "./paymentState";
import { SET_SECRET } from "./paymentActions";

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SECRET:
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
        country: action.payload.country,
      };
    default:
      return state;
  }
};
export default reducer;
