/** @jsxImportSource theme-ui */
import { ThemeUICSSObject } from "theme-ui";
import { HTMLProps, forwardRef } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

type ButtonProps = HTMLProps<HTMLButtonElement> & {
  label: string | JSX.Element | any;
  type?: "button" | "reset" | "submit";
  variation?: "normal" | "small";
  to?: string;
  "data-cy"?: string;
};

const styles: (isSmall: boolean, isPressed: boolean) => ThemeUICSSObject = (
  isSmall,
  isPressed
) => ({
  alignSelf: "center",
  backgroundColor: "primary",
  padding: [2, 3],
  borderRadius: 3,
  maxHeight: 70,
  margin: [2, 3],
  fontWeight: "bold",
  textDecoration: "none",
  lineHeight: ["20px", "20px", "30px"],
  color: "backdrop",
  fontSize: isSmall ? [1, 1, 2] : [2, 2, 4],
  boxShadow: "1px 2px 1px 1px black",
  verticalAlign: "revert",
  display: "inline-block",
  transitionDuration: ".2s",
  transformOrigin: "center bottom 0px",
  transform: isPressed ? "matrix(0.85, 0, 0, 0.85, 0, 0)" : "matrix(1, 0, 0, 1, 0, 0)",
  ":hover": {
    ":enabled": {
      boxShadow: "0px 0px 0px 0px",
    },
  },
  ":focus": {
    outline: "none",
    color: "accent",
  },
  ":disabled": {
    backgroundColor: "backdrop",
    color: "text",
  },
});

export const ButtonLabelStyle: ThemeUICSSObject = {
  fontFamily: `'Anton', sans-serif`,
  textTransform: "uppercase",
  fontWeight: 300,
};

export const Button = forwardRef(
  ({ label, type = "button", variation = "normal", to, ...rest }: ButtonProps) => {
    const [pressed, setPressed] = useState(false);
    const contents = typeof label === "string" ? <span sx={ButtonLabelStyle}>{label}</span> : label;

    return to ? (
      <Link
        sx={styles(variation === "small", pressed)}
        to={to}
        {...(rest as any)}
        onMouseDown={() => setPressed(true)}
        onMouseUp={() => setPressed(false)}
        onMouseOut={() => setPressed(false)}
        onTouchStart={() => setPressed(true)}
        onTouchEnd={() => setPressed(false)}
      >
        {contents}
      </Link>
    ) : (
      <button
        sx={styles(variation === "small", pressed)}
        type={type}
        onMouseDown={() => setPressed(true)}
        onMouseUp={() => setPressed(false)}
        onMouseOut={() => setPressed(false)}
        onTouchStart={() => setPressed(true)}
        onTouchEnd={() => setPressed(false)}
        {...rest}
      >
        {contents}
      </button>
    );
  }
);
