/** @jsxImportSource theme-ui */
import { Flex, Themed, ThemeUICSSObject } from "theme-ui";
import { useEffect } from "react";
import { connect } from "react-redux";
import { LogOnMount } from "@amplitude/react-amplitude";

import { disconnectAction } from "../../store/connection/connectionActions";
import { PlayScene } from "../../game/components/play-scene";
import { TitleLogo } from "../../components/TitleLogo";
import { Button } from "../../components/Button";
import { Pages, PageWrapper } from "../pageWrapper";
import { Link } from "react-router-dom";
import { Demo } from "./demo";
import { LogoutButton } from "../../components/LogoutButton/logout";

const dispatchProps = {
  disconnect: disconnectAction,
};

const Home = ({ disconnect }: typeof dispatchProps) => {
  // disconnect users arriving back at home
  useEffect(() => {
    disconnect();
  }, [disconnect]);

  const wordSpacing: ThemeUICSSObject = {
    maxWidth: "600px",
    marginLeft: "auto",
    marginRight: "auto",
  };

  return (
    <PageWrapper type={Pages.LOBBY}>
      <LogOnMount eventType="homePage" />

      <div sx={{ height: "80vh", minHeight: "500px" }}>
        <div sx={{ marginBottom: 6 }}>
          <TitleLogo />
        </div>

        <Themed.h4>A collaborative storytelling game full of laughs</Themed.h4>
        <div sx={{ margin: 6 }}>
          <img
            sx={{ height: 26, width: 200 }}
            alt="Powered By Giphy"
            src="/PoweredBy_200px-Black_HorizLogo.png"
          />
        </div>

        <div sx={{ marginBottom: [4, 8] }}></div>

        <Flex
          sx={{ flexDirection: "row", alignItems: "center", justifyContent: "center", margin: 7 }}
        >
          <Button type="button" to="/start" label="Start Story" data-cy="start-story" />
          <Button sx={{ backgroundColor: "text" }} type="button" to="/join" label="Join Story" />
        </Flex>
      </div>

      <LogoutButton>
        <div sx={{ margin: 4, marginBottom: "25vh" }}>
          <Button
            sx={{ backgroundColor: "text" }}
            type="button"
            to="/account"
            label="Go to Account"
            variation="small"
          />
        </div>
      </LogoutButton>

      <Flex
        sx={{
          flexDirection: "column",
          height: "40vh",
          minHeight: "400px",
          justifyContent: "center",
          marginBottom: "20vh",
        }}
        // style={{ contentVisibility: "" }}
      >
        <Themed.p>Our Story is a game where players create a story together</Themed.p>
        <PlayScene finalString="This got their attention" gif="FZdSnStFdvGIMV8g0J" />
      </Flex>

      <Demo />

      <Flex
        sx={{
          flexDirection: "column",
          height: "50vh",
          minHeight: "300px",
          justifyContent: "center",
        }}
      >
        <Themed.p sx={{ ...wordSpacing }}>
          It's a funny and entertaining way of making stories and we look forward to seeing what you
          create
        </Themed.p>

        <Button type="button" to="/start" label="Try a game today" variation="small" />
      </Flex>

      <p>Copyright Hofferber Interactive, Inc. 2021</p>
      <Flex sx={{ alignItems: "center", alignContent: "center", justifyContent: "center" }}>
        <Link sx={{ textDecoration: "none", color: "primary", marginX: 3 }} to="/privacy">
          <p sx={{ marginTop: 0 }}>Privacy Policy</p>
        </Link>
        <Link sx={{ textDecoration: "none", color: "primary", marginX: 3 }} to="/terms">
          <p sx={{ marginTop: 0 }}>Terms and Conditions</p>
        </Link>
      </Flex>
    </PageWrapper>
  );
};

export const HomePage = connect(null, dispatchProps)(Home);
