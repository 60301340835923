import { ConfigData } from "./lobbyState";

const prefix = (name: string) => `@lobby/${name}`;

export const CREATE = prefix("CREATE");
export const CREATE_ERROR = prefix("CREATE_ERROR");
export const GET_CONNECTION = prefix("GET_CONNECTION");
export const CONNECTION = prefix("CONNECTION");
export const CREATED = prefix("CREATED");
export const JOIN = prefix("JOIN");
export const JOINED = prefix("JOINED");
export const JOIN_ERROR = prefix("JOIN_ERROR");
export const CONFIGURE = prefix("CONFIGURE");
export const UPDATED = prefix("UPDATED");
export const INTERACT = prefix("INTERACT");
export const CLEAR = prefix("CLEAR");

export const createAction = (playerName: string) => ({
  type: CREATE,
  payload: { playerName },
});

export const createErrorAction = ({ message }: { message: string }) => ({
  type: CREATE_ERROR,
  payload: { message },
});

export const createdAction = (roomKey: string) => ({
  type: CREATED,
  payload: { roomKey },
});

export const getConnectionAction = () => ({
  type: GET_CONNECTION,
});

export const connectionAction = (connectionId: string) => ({
  type: CONNECTION,
  payload: { connectionId },
});

export type JoinAction = { playerName: string; roomKey: string };
export const joinAction = ({ playerName, roomKey }: JoinAction) => ({
  type: JOIN,
  payload: { playerName, roomKey },
});

export const joinedAction = (roomKey: string) => ({
  type: JOINED,
  payload: { roomKey },
});

export const joinErrorAction = ({ message }: { message: string }) => ({
  type: JOIN_ERROR,
  payload: { message },
});

export const configureAction = (roomKey: string, config: Partial<ConfigData>) => ({
  type: CONFIGURE,
  payload: { roomKey, config },
});

export const updatedAction = (lobbyData: Array<any>) => ({
  type: UPDATED,
  payload: lobbyData,
});

export const interactAction = (data: any) => ({
  type: INTERACT,
  payload: data,
});

export const clearAction = () => ({
  type: CLEAR,
});
