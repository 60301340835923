export type PlayerData = {
  roomKey: string;
  userConnection: string;
  name: string;
  host?: boolean;
  ttl: number;
  authState?: {
    paidAccount: boolean;
    isAuthenticated: boolean;
  };
};

export type ConfigData = {
  roomKey: string;
  userConnection: string;
  stageLength?: { [key: number]: number };
  lastRound?: number;
  voice?: string;
  language?: string;
  gifSearchRating?: string;
};

export type GameData = {
  roomKey: string;
  userConnection: string;
  round: { number: number; stage: number; startTime: number };
  rounds: {
    [key: string]: {
      template?: string;
      scene?: string;
      gif?: string;
      audio?: { src: string; ready: boolean };
    };
  }[];
  options: {
    [key: string]: { name: string[]; action: string[]; place: string[] };
  };
  usedOptions: {
    [key: string]: string[];
  };
  votes: { [key: string]: number };
  theme?: string;
  uuid?: string;
};

export type LobbyState = {
  roomKey: null | string;
  lastRoomKey: null | string;
  myName: string;
  isHost: boolean;
  lobbyData: Array<PlayerData & ConfigData & GameData>;
  connectionId: string;
  error?: string;
};

export default {
  roomKey: null,
  lastRoomKey: null,
  myName: "",
  isHost: false,
  lobbyData: [],
  connectionId: "",
} as LobbyState;
