/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import { PlayerMarker } from "../../components/PlayerMarker";
import { getAuthorInputCount } from "../selector/selector";

const mapToState = createSelector([getAuthorInputCount], (authorInputs) => ({ authorInputs }));

type Props = ReturnType<typeof mapToState>;

const PlayerOptionCountComponent = ({ authorInputs }: Props) => {
  return (
    <div>
      <Flex
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
          justifyContent: "center",
          "> div": { margin: 3 },
        }}
      >
        {Object.keys(authorInputs).map((author, i) => (
          <PlayerMarker
            key={`${i}-${author}`}
            name={`${author} - ${authorInputs[author].count}`}
            colorIndex={authorInputs[author].index}
          />
        ))}
      </Flex>
    </div>
  );
};

export const PlayerOptionCount = connect(mapToState)(PlayerOptionCountComponent);
