/** @jsxImportSource theme-ui */

import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import "./index.scss";
// import reportWebVitals from "./reportWebVitals";
import { awsConfig } from "./config";

Sentry.init({
  dsn: "https://f213d80412b943adaab14ead3feae851@o370066.ingest.sentry.io/5602808",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: "our-story-gif@" + (process.env.REACT_APP_VERSION || "unreleased"),
  autoSessionTracking: true,
  environment: process.env.NODE_ENV,
});

Amplify.configure(awsConfig);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
