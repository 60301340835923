/** @jsxImportSource theme-ui */
import { Themed } from "theme-ui";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { LogOnMount } from "@amplitude/react-amplitude";

import { Button } from "../../components/Button";
import { FormRow } from "../../components/FormRow";
import { TextField } from "../../components/TextField";
import { AuthWrapper } from "./wrapper";

const ForgotPasswordSchema = Yup.object().shape({ username: Yup.string().required() });

export const ForgotPassword = () => {
  const [error, setError] = useState<string>();
  const [requestedUsername, setUsername] = useState("");

  return (
    <AuthWrapper>
      <LogOnMount eventType="forgotPassPage" />
      {requestedUsername ? (
        <Redirect to={{ pathname: `/forgot-password/${requestedUsername}` }} />
      ) : (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={ForgotPasswordSchema}
          onSubmit={(values) => {
            Auth.forgotPassword(values.username.toLocaleLowerCase())
              .then(() => {
                console.log("success");
                setUsername(values.username);
              })
              .catch((err) => {
                setError(err.message);
                console.log("err", err);
              });
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Themed.h4>Forgot Password</Themed.h4>

              <FormRow>
                <TextField
                  name="username"
                  label="Email Username"
                  required
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.username}
                />
              </FormRow>

              <Button
                sx={{ margin: 3, fontSize: 3, backgroundColor: "text" }}
                type="submit"
                label="Recover Account"
              />

              {error && (
                <p
                  sx={{
                    color: "error",
                    fontSize: [1, 2, 2],
                  }}
                >
                  {error}
                </p>
              )}
            </form>
          )}
        </Formik>
      )}
    </AuthWrapper>
  );
};
