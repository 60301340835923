/** @jsxImportSource theme-ui */
import amplitude from "amplitude-js";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Flex, Themed } from "theme-ui";
import { AUTH_USERNAME, AUTH_USER_TOKEN_KEY } from "../../config";

export const LogoutButton = ({ children }: { children?: JSX.Element }) => {
  const username = localStorage?.getItem(AUTH_USERNAME);
  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.setUserId(username);
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        // Align Google Login with Email Login
        if (!username) {
          localStorage?.setItem(AUTH_USERNAME, session.getIdToken().payload.email || "");
          amplitudeInstance.setUserId(username);
        }
        const token = localStorage?.getItem(AUTH_USER_TOKEN_KEY);
        if (!token) {
          localStorage?.setItem(AUTH_USER_TOKEN_KEY, session.getAccessToken().getJwtToken());
        }

        setLoggedIn(true);
      })
      .catch(() => {
        /* noop */
      });
  });

  return (
    (username && isLoggedIn && (
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Themed.p sx={{ fontSize: 0, marginBottom: 1 }}>Logged in as {username}</Themed.p>
        <Link
          sx={{ textDecoration: "none" }}
          onClick={async () => {
            await Auth.signOut();
            localStorage.removeItem(AUTH_USERNAME);
            console.log("signed out");
          }}
          to="/login"
        >
          <Themed.h4 sx={{ color: "primary" }}>Logout</Themed.h4>
        </Link>

        {children}
      </Flex>
    )) || <div></div>
  );
};
