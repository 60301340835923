const prefix = (name: string) => `@payment/${name}`;

export const GET_SECRET = prefix("GET_SECRET");
export const SET_SECRET = prefix("SET_SECRET");

export const getSecretAction = (username: string) => ({
  type: GET_SECRET,
  payload: { username },
});

export const setSecretAction = ({clientSecret, country}: {clientSecret: string, country: string}) => ({
  type: SET_SECRET,
  payload: { clientSecret, country },
});
