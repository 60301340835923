export const shuffle = (array: any[]) => {
  let currentIndex: number = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

/**
 * Return the max length of the lists given
 * @param arr list of lists to be checked
 */
export const getMaxListLength = (arr: any[][]) =>
  arr.map((list) => list.length).reduce((curr, prev) => (curr > prev ? curr : prev), arr[0].length);

/**
 * Get the word selection available for a user. Order will change how words are grapped
 *
 * @param actions full list of options in the lobby
 * @param connectionId player's connection id to use to filter options
 * @param isInverseOrder grab words from the end of the lists instead of the beginning
 */
export const getWordSelection = (
  actions: { [key: string]: string[] },
  connectionId: string,
  randomPool: string[],
  isInverseOrder?: boolean
) => {
  const playerEntries = Object.keys(actions)
    .filter((action) => action !== connectionId)
    .map((key) => actions[key]);

  if (playerEntries.length === 0) {
    return [randomPool[0], randomPool[1], randomPool[2]];
  }

  let playerIndex = Object.keys(actions).indexOf(connectionId);
  if (playerIndex === playerEntries.length || playerIndex === -1) {
    playerIndex = 0; // no player entry default
  }

  // cycle through available words
  const maxLength = getMaxListLength(playerEntries);
  let returnList = [];
  for (let i = 0; i < maxLength; i++) {
    returnList.push(
      isInverseOrder
        ? playerEntries[playerIndex][playerEntries[playerIndex].length - i - 1]
        : playerEntries[playerIndex][i]
    );
    playerIndex++;
    if (playerIndex === playerEntries.length) {
      playerIndex = 0;
    }
  }

  // populate missing words with random values
  returnList = returnList.filter(Boolean);
  let x = 0;
  while (returnList.length < 3) {
    returnList.push(randomPool[x++]);
  }

  return returnList;
};

export const filterOutUsed = (
  selection: { [key: string]: string[] },
  used: { [key: string]: string[] }
) => {
  return Object.keys(selection).reduce((prev, curr) => {
    return { ...prev, [curr]: selection[curr].filter((item) => !used[curr]?.includes(item)) };
  }, {});
};

export const filterOut = (selection: string[], remove: string[]) => {
  return selection.filter((item) => !remove?.includes(item));
};

/**
 * Select the first few elements of the array
 * @param array any list
 * @param count number of elements to return from the list
 */
export const pick = (array: any[], count: number) =>
  array.reduce((prev, curr, index) => (index >= count ? prev : [...prev, curr]), []);
