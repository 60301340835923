/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";
import { useState, useEffect, FC } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import { Beforeunload } from "react-beforeunload";
import { Auth } from "aws-amplify";

import { interactAction } from "../../store/lobby/lobbyActions";
import { getRoomKey } from "../../store/lobby/lobbySelector";
import { getConnectionState } from "../../store/connection/connectionSelector";
import { getGamePhase, getIsHost, getPreloadAudio } from "../selector/gameSelector";
import {
  currentRoundScenes,
  getPlayerOptions,
  finalRoundScenes,
  getShareLink,
} from "../selector/selector";
import { openerTemplates, midgameTemplates, endgameTemplates } from "../constants/templates";
import { GamePhase } from "../constants/gamePhases";
import { HostComponent } from "../host/hostComponent";
import { pick, shuffle } from "../../util/shuffle";

import { NarrationComponent } from "../components/narration";
import { LoadingPage } from "../components/loading-page";
import { PlayerOptionCount } from "../components/player-option-count";
import { PlayerSubmittedStatus } from "../components/player-submitted-status";
import { PlayerTemplateStatus } from "../components/player-template-status";
import { Logo } from "../../components/TitleLogo";
import { InputOptions } from "../phases/addOptions";
import { TemplatePick } from "../phases/pickTemplate";
import { PickScene } from "../phases/pickScene";
import { ReviewScenes } from "../phases/reviewScenes";
import { VoteScenes } from "../phases/vote";
import { AUTH_USER_TOKEN_KEY, ENABLE_FORCE_NEXT } from "../../config";
import { Button } from "../../components/Button";

const mapToState = createSelector(
  [
    getRoomKey,
    getIsHost,
    getConnectionState,
    currentRoundScenes,
    getGamePhase,
    getPlayerOptions,
    getPreloadAudio,
    finalRoundScenes,
    getShareLink,
  ],
  (
    roomKey,
    isHost,
    connectionState,
    roundScenes,
    gamePhase,
    playerOptions,
    preloadAudio,
    finalRoundScenes,
    shareLink
  ) => ({
    roomKey,
    isHost,
    isConnected: connectionState.isConnected,
    roundScenes,
    gamePhase,
    playerOptions,
    preloadAudio,
    finalRoundScenes,
    shareLink,
  })
);

const openers = pick(shuffle(openerTemplates), 3);
const midScenes = pick(shuffle(midgameTemplates), 3);
const closers = pick(shuffle(endgameTemplates), 3);

const dispatchProps = {
  interact: interactAction,
};

type Props = ReturnType<typeof mapToState> & typeof dispatchProps;

const Game: FC<Props> = ({
  roomKey,
  isHost,
  isConnected,
  roundScenes,
  gamePhase,
  playerOptions,
  preloadAudio,
  finalRoundScenes,
  shareLink,
  interact,
}) => {
  const [selectedTemplate, setTemplate] = useState(midgameTemplates[0]);
  const phaseDuration = (gamePhase?.seconds || 0) * 1000;

  useEffect(() => {
    if (gamePhase.phase === GamePhase.FinalReview && finalRoundScenes && isHost) {
      Auth.currentSession().then((info) => {
        const groups = info.getAccessToken().payload["cognito:groups"];
        if (!groups || groups.includes("New")) {
          Promise.all([Auth.currentAuthenticatedUser(), Auth.currentSession()])
            .then(([cognitoUser, currentSession]) => {
              cognitoUser.refreshSession(
                currentSession.getRefreshToken(),
                (err: any, session: any) => {
                  if (err) {
                    Auth.signOut().then((result) => console.log("logged out", result));
                  } else {
                    localStorage.setItem(AUTH_USER_TOKEN_KEY, session.accessToken.jwtToken);
                  }
                }
              );
            })
            .catch(() => {
              Auth.signOut().then((result) => console.log("logged out", result));
            });
        }
      });
    }
  }, [gamePhase, finalRoundScenes, isHost]);

  const key = "LAST_PHASE";
  useEffect(() => {
    localStorage.removeItem(key);
    return () => {
      localStorage.removeItem(key);
    };
  }, [key]);

  const nextSceneHandler = () => {
    const lastPhaseStr = localStorage.getItem(key);
    const lastPhase = Number.parseInt(lastPhaseStr || "-1");
    console.debug(`game phase is ${gamePhase.phase} and last phase is ${lastPhase}`);

    if (isHost && gamePhase.phase !== lastPhase) {
      console.debug(`setting last phase to ${gamePhase.phase.toString()}`);
      localStorage.setItem(key, gamePhase.phase.toString());

      interact({
        roomKey,
        payload: {
          type: "next",
          isNextRound:
            (gamePhase.phase === GamePhase.Watch &&
              gamePhase.roundNumber !== gamePhase.lastRound) ||
            gamePhase.phase === GamePhase.Voting,
          isFinalStage: gamePhase.phase === GamePhase.Voting,
        },
      });
    }
  };

  return (
    <Amplitude
      eventProperties={{
        scope: ["game"],
        roomKey,
        gamePhase,
      }}
    >
      {isConnected ? (
        <div>
          <LogOnMount eventType="startGame" />
          <Beforeunload onBeforeunload={() => "Leaving will cause you to reconnect"} />
        </div>
      ) : (
        <Redirect to="/" />
      )}

      {Object.keys(preloadAudio).map(
        (url) =>
          preloadAudio[url] && (
            <link
              key={`preload-${url}`}
              rel="prefetch"
              href={url}
              as="fetch"
              crossOrigin="anonymous"
            />
          )
      )}

      <Flex
        sx={{
          minHeight: "100vh",
          flexWrap: "wrap",
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        {gamePhase.phase !== GamePhase.Watch && (
          <HostComponent nextSceneHandler={nextSceneHandler} />
        )}

        <Logo smaller />

        <NarrationComponent gamePhase={gamePhase} />

        {gamePhase.phase === GamePhase.Waiting && <LoadingPage phaseDuration={phaseDuration} />}

        {gamePhase.phase === GamePhase.SelectTemplate && (
          <div>
            <TemplatePick
              templates={
                !gamePhase.roundNumber || gamePhase.roundNumber === 1
                  ? openers
                  : gamePhase.roundNumber === 2 || gamePhase.roundNumber === 3
                  ? midScenes
                  : closers
              }
              phaseDuration={phaseDuration}
              selectTemplate={(template) => {
                interact({
                  roomKey,
                  payload: { type: "template-pick", template },
                });
                setTemplate(template);
              }}
            />

            {gamePhase.roundNumber === 1 ? (
              <PlayerOptionCount />
            ) : (
              <PlayerTemplateStatus nextScene={nextSceneHandler} />
            )}
          </div>
        )}

        {gamePhase.phase === GamePhase.AddOptions && (
          <div>
            <InputOptions
              phaseDuration={phaseDuration}
              round={gamePhase.roundNumber}
              submitValue={(type, value) => {
                interact({
                  roomKey,
                  payload: {
                    type: "update-options",
                    choice: { key: type, value },
                  },
                });
              }}
            />

            <PlayerOptionCount />
          </div>
        )}

        {gamePhase.phase === GamePhase.SelectOptions && (
          <div>
            <PickScene
              playerOptions={playerOptions}
              roomKey={roomKey}
              selectedTemplate={selectedTemplate}
              phaseDuration={phaseDuration}
              interact={interact}
            />

            <PlayerSubmittedStatus nextScene={nextSceneHandler} />
          </div>
        )}

        {gamePhase.phase === GamePhase.Watch && (
          <ReviewScenes roundScenes={roundScenes} stageComplete={nextSceneHandler} />
        )}

        {gamePhase.phase === GamePhase.Voting && (
          <VoteScenes
            roundScenes={roundScenes}
            phaseDuration={phaseDuration}
            roomKey={roomKey}
            interact={interact}
          />
        )}

        {gamePhase.phase === GamePhase.FinalReview && finalRoundScenes && (
          <div>
            <LogOnMount eventType="finishGame" />
            <ReviewScenes roundScenes={finalRoundScenes} shareLink={shareLink} />
          </div>
        )}

        {ENABLE_FORCE_NEXT === "true" && isHost && (
          <Button label="Force Next" onClick={nextSceneHandler} data-cy="force-next-button" />
        )}
      </Flex>
    </Amplitude>
  );
};

export const RenderManager = connect(mapToState, dispatchProps)(Game);
