import { createContext } from "react";

export type AudioContextType = {
  context: AudioContext;
  isMuted: boolean;
  volume: string;
  setAudio: (context?: any) => void;
};

const WindowAudioContext = window.AudioContext || (window as any).webkitAudioContext;
export const AudioContext = createContext<AudioContextType>({
  context: new WindowAudioContext(),
  isMuted: false,
  volume: "100",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAudio: () => {},
});
