// example base theme from @theme-ui/presets
import { Theme } from "theme-ui";

const heading = {
  fontFamily: "heading",
  lineHeight: "heading",
  fontWeight: "heading",
  color: "text",
};

// example theme.js
const theme: Theme = {
  // maps to: responsive, 600px max, 800px max, 1200px max
  breakpoints: ["600px", "800px", "1000px", "1001px"],
  fonts: {
    body: "system-ui, sans-serif",
    heading: '"Avenir Next", sans-serif',
    monospace: "Menlo, monospace",
  },
  colors: {
    text: "#302f2d",
    light: "#54524F",
    background: "#222222",
    backdrop: "#EAEADF",
    area: "#E5DBB2",
    aside: "#E69E59",
    primary: "#364e54",
    secondary: "#bf8176",
    error: "#CC5F38",
    focus: "#1FB8C9",
    accent: "#bf8176",
    modes: {
      dark: {
        text: "#EAEADF",
        light: "#D7D7C1",
        background: "#222222",
        backdrop: "#364E54",
        area: "#20373D",
        aside: "#7A5868",
        primary: "#F2DEBD",
        secondary: "#CAC0AA",
        error: "#FCA6A8",
        focus: "#4AD4E3",
        accent: "#BF8176",
      },
    },
  },
  fontSizes: [14, 16, 20, 24, 32, 48, 64, 72],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacings: {
    body: "normal",
    caps: "0.2em",
  },
  space: [0, 4, 8, 12, 16, 24, 32, 48, 52, 72, 100],
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      ...heading,
      fontSize: [4, 5, 6],
    },
    h2: {
      ...heading,
      fontSize: [4, 4, 5],
    },
    h3: {
      ...heading,
      fontFamily: `'Anton', sans-serif`,
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: [3, 3, 4],
      margin: [3, 3, 4],
    },
    h4: {
      ...heading,
      fontFamily: `'Anton', sans-serif`,
      textTransform: "uppercase",
      fontSize: 2,
      margin: [2, 2, 3],
      letterSpacing: "1px",
      fontWeight: 300,
    },
    p: {
      fontSize: [2, 3, 3],
    },
    a: {
      fontSize: 2,
      color: "primary",
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    Container: {
      padding: 3,
    },
  },
};

export default theme;
