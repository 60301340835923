/** @jsxImportSource theme-ui */
import { Container, useColorMode } from "theme-ui";
import { Amplitude } from "@amplitude/react-amplitude";

import { Logo } from "../../components/TitleLogo";

import styles from "./wrapper.module.scss";

export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const [, setColorMode] = useColorMode();
  setColorMode("dark");

  return (
    <Amplitude
      eventProperties={{
        scope: ["auth"],
      }}
    >
      <div
        className={styles["dark"]}
        sx={{
          backgroundColor: "backdrop",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: ["100%", "600px", "800px", "1000px"],
          minHeight: "100vh",
        }}
      >
        <Container
          sx={{
            width: ["75%", "69%", "59%"],
            textAlign: "center",
          }}
        >
          <Logo />
          {children}
        </Container>
      </div>
    </Amplitude>
  );
};
