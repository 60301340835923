/** @jsxImportSource theme-ui */
import { Flex, Themed, ThemeUICSSObject } from "theme-ui";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";

export const Accordion = ({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) => {
  const Themedown: ThemeUICSSObject = {
    justifyContent: "center",
    alignItems: "center",
    margin: [3, 4, 4],
  };

  return (
    <div>
      {isOpen ? (
        <Flex sx={Themedown} onClick={() => setOpen(!isOpen)}>
          <Themed.a sx={{ fontSize: 1 }}>Close</Themed.a>
          <UilUp size={28} />
        </Flex>
      ) : (
        <Flex sx={Themedown} onClick={() => setOpen(!isOpen)}>
          <Themed.a sx={{ fontSize: 2 }}>Game Options</Themed.a>
          <UilDown size={28} />
        </Flex>
      )}
    </div>
  );
};
