/** @jsxImportSource theme-ui */
import { Container, Flex } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Carousel } from "@giphy/react-components";
import { IGif } from "@giphy/js-types";
import { useState, useEffect, useRef, FC } from "react";

import { TextField } from "../TextField";
import { GIPHY_KEY } from "../../config";
import { Button } from "../Button";

const giphyFetch = new GiphyFetch(GIPHY_KEY);

function GifCarousel({
  searchTerm,
  onSelect,
}: {
  searchTerm: string;
  onSelect: (gif: IGif) => void;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const gifHeight: number = useResponsiveValue([100, 150, 180]);
  const fetchGifs = useRef((offset: number) =>
    giphyFetch.search(searchTerm, { offset, limit: 10 })
  );

  // Needs to be at least 1000 ms timeout to not trigger endless re-requests
  // Likely need to make a custom component to replace the Carousel to fix
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    fetchGifs.current = (offset: number) =>
      giphyFetch.search(searchTerm, { offset, limit: 10, rating: "pg-13" });
  }, [searchTerm]);

  return !isLoading ? (
    <Carousel
      user={{ user_type: "anonymous" }}
      fetchGifs={fetchGifs.current}
      gifHeight={gifHeight}
      gutter={6}
      onGifClick={(gif: IGif, e) => {
        e.preventDefault();
        onSelect(gif);
      }}
      hideAttribution
    />
  ) : (
    <div></div>
  );
}

type Props = {
  defaultSearch: string;
  onSelect: (gif: IGif) => void;
};

export const GifSearch: FC<Props> = ({ onSelect, defaultSearch }) => {
  const [searchTerm, setSearchTerm] = useState(defaultSearch || "");
  const logoWidth: number = useResponsiveValue([150, 200, 200]);
  const smallRes = logoWidth < 200;

  return (
    <div sx={{ position: "absolute" }}>
      <Container
        sx={{
          bottom: 0,
          left: 0,
          position: "fixed",
          backgroundColor: "backdrop",
          borderTop: "3px solid",
          borderColor: "accent",
          zIndex: 20,
        }}
      >
        <Flex sx={{ flexDirection: "column" }}>
          <Flex
            sx={{
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <Flex sx={{ alignItems: "center" }}>
              <TextField
                sx={{ marginBottom: -3 }}
                name="roomId"
                label="GIF Search"
                min="4"
                value={searchTerm}
                onChange={(e: any) => {
                  setSearchTerm(e.target.value);
                }}
              />
              <Button
                variation="small"
                label="x"
                onClick={() => {
                  setSearchTerm("");
                }}
              />
            </Flex>
            <div sx={{ marginBottom: smallRes ? -3 : 0 }}>
              <img
                alt="Powered By Giphy"
                src="/PoweredBy_200_Horizontal_Light-Backgrounds_With_Logo.gif"
                sx={{ width: logoWidth, padding: 1 }}
              />
            </div>
          </Flex>
          <div sx={{ height: [120, 170, 200] }}>
            <GifCarousel searchTerm={searchTerm} onSelect={onSelect} />
          </div>
        </Flex>
      </Container>
      <div sx={{ height: [200, 300] }} />
    </div>
  );
};
