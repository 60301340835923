/** @jsxImportSource theme-ui */
import { Themed } from "theme-ui";
import { FC } from "react";
import { LogOnMount } from "@amplitude/react-amplitude";

import { Pages, PageWrapper } from "../../pages/pageWrapper";
import { Logo } from "../../components/TitleLogo";

type Props = {
  error: Error;
};

export const NotFoundPage: FC<Props> = ({ error }) => {
  console.error(error);

  return (
    <PageWrapper type={Pages.LOBBY}>
      <LogOnMount eventType="NotFoundPage" />
      <Logo />

      <Themed.h4>Not Found</Themed.h4>
      <Themed.p>The page you are looking for does not exist.</Themed.p>
    </PageWrapper>
  );
};
