/** @jsxImportSource theme-ui */
import { Flex, Themed } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";
import { useState } from "react";
import { Gif } from "@giphy/react-components";
import { IGif } from "@giphy/js-types";

import { Button } from "../../components/Button";
import { GifSearch } from "../../components/GifSearch";
import { borderCardStyle } from "./play-scene";

type Props = {
  selectedAction: string;
  finalScene: string;
  selectGif: (gif: IGif) => void;
};

export const PickGif = ({ selectedAction, finalScene, selectGif }: Props) => {
  const [selectedGif, setGif] = useState<IGif | undefined>(undefined);
  const gifWidth: number = useResponsiveValue([250, 300]);

  return (
    <Flex
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: [300, 400, 600],
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!selectedGif && <Themed.h4 data-cy="select-gif-title">Choose a GIF</Themed.h4>}

      {selectedGif && (
        <Button
          onClick={() => {
            selectGif(selectedGif);
          }}
          label="Select"
          data-cy="select-gif-button"
        />
      )}

      <div sx={borderCardStyle(false)}>
        <Themed.h3 sx={{ textAlign: "center" }}>{finalScene}</Themed.h3>

        {selectedGif && (
          <Gif
            gif={selectedGif}
            width={gifWidth}
            hideAttribution
            onGifClick={(gif, e) => e.preventDefault()}
          />
        )}
      </div>
      <GifSearch defaultSearch={selectedAction} onSelect={(gif) => setGif(gif)} />
    </Flex>
  );
};
