/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";
import { useState } from "react";
import { keyframes } from "@emotion/react";
import { Formik } from "formik";

import { FormRow } from "../../components/FormRow";
import { Button } from "../../components/Button";
import { RadioGroup } from "../../components/RadioGroup/radioGroup";
import { Accordion } from "../../components/Accordion/accordion";

const fadeInDown = keyframes({
  from: { opacity: 0, transform: "translate3d(0, -100%, 0)" },
  to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
});

type ConfigurationOutput = {
  voice: string;
  language: string;
  gifSearchRating: string;
};

export const Configuration = ({
  onSubmit,
}: {
  onSubmit: (output: ConfigurationOutput) => void;
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Accordion isOpen={isOpen} setOpen={setOpen} />

      {isOpen && (
        <Formik
          initialValues={{ voice: "Brian", language: "en-US", gifSearchRating: "pg-13" }}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                animation: `0.3s ${fadeInDown}`,
                textAlign: "left",
              }}
              autoComplete="off"
              onSubmit={(element) => {
                return handleSubmit(element);
              }}
            >
              <Flex
                sx={{
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  maxWidth: "500px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <RadioGroup fieldName={"voice"} legend={"Voice"} options={["Brian", "Joanna"]} />

                <RadioGroup fieldName={"language"} legend={"Language"} options={["en-US"]} />

                <RadioGroup
                  fieldName={"gifSearchRating"}
                  legend={"Gif Search Rating"}
                  options={["g", "pg", "pg-13", "r"]}
                />

                <FormRow>
                  <Button
                    sx={{ margin: 3, fontSize: [1, 1, 2], backgroundColor: "text" }}
                    type="submit"
                    disabled={isSubmitting}
                    label="Update"
                    variation="small"
                  />
                </FormRow>
              </Flex>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};
