/** @jsxImportSource theme-ui */
import { Themed, ThemeUICSSObject } from "theme-ui";
import { TitleLogo } from "../../components/TitleLogo";
import { Pages, PageWrapper } from "../pageWrapper";

export const Terms = () => {
  const emailAddress = "privacy@ourstorygif.com";
  const insetStyle: ThemeUICSSObject = { marginLeft: 4 };

  return (
    <PageWrapper type={Pages.LOBBY}>
      <div sx={{ marginBottom: 6 }}>
        <TitleLogo />
      </div>

      <Themed.h2>Terms of Service</Themed.h2>

      <div sx={{ textAlign: "left", margin: 2 }}>
        <p>
          This document (the “Agreement”) constitutes a legal agreement between you and Hofferber
          Interactive Inc., and its related companies and affiliates (collectively, “HII,” “we,” or
          “us”) that governs the relationship between you and HII with respect to your use of HII’s
          games, products, services and websites accessed through internet-capable platforms,
          including game consoles, personal computers, mobile devices, and in-game applications and
          software hosted by third-parties. (collectively, the “Services”).
        </p>

        <p>
          Please read the following Agreement carefully. By using or accessing for the Service, you
          consent to this Agreement and to our Privacy Policy.
        </p>

        <p>
          <b>ACCESS TO THE SERVICE</b>
        </p>
        <p sx={insetStyle}>
          In order to use or access the Service, you affirm that (i) you are at least 18 years of
          age (or have reached the age of majority if that is not 18 years of age where you live) or
          you have reviewed this Agreement with your parent or guardian and he or she assents to
          this Agreement on your behalf and takes full responsibility for your compliance with them;
          and (ii) you are a legal resident of a country where your access to and your play,
          purchase, receipt and use of the applicable Services are permitted. You represent that you
          are fully able and competent to enter into the terms, conditions, and representations set
          forth in this Agreement.
        </p>

        <p sx={insetStyle}>
          If you use or access the Service through a social network (e.g., Twitch) or other third
          party platform, game console, app, or service (“Third Party Service”), you may be required
          to have an account with the Third Party Service through which you connect to the Service.
          You agree to comply with the Third Party Service’s terms of use as well as this Agreement.
          You also agree that HII has no responsibility or liability for any act, error, or omission
          of any Third Party Service.
        </p>

        <p sx={insetStyle}>
          The collection and use of your information, whether extracted from a Third Party Service
          or otherwise provided to HII is subject to our Privacy Policy. You agree that HII may use
          your information, game history and other data related to your use of the Service as
          provided in our Privacy Policy.
        </p>

        <p>
          <b>LIMITED LICENSE TO USE THE SERVICE</b>
        </p>
        <p sx={insetStyle}>
          Subject to your compliance with this Agreement, HII grants you a limited, revocable,
          non-exclusive, non-transferable, non-sublicensable license to use and access the Service,
          solely for your personal, non-commercial use.
        </p>

        <p sx={insetStyle}>
          You agree not to (a) use the Service for any use or purpose other than as expressly
          permitted by this Agreement, or (b) copy, adapt, modify, prepare derivative works based
          upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit,
          stream, broadcast or otherwise exploit the Service or any portion thereof, except as
          expressly permitted in this Agreement. No licenses or rights are granted to you by
          implication or otherwise under any intellectual property rights owned or controlled by HII
          or its licensors, except for the permissions and rights expressly granted in this
          Agreement.
        </p>

        <p sx={insetStyle}>
          If you violate this Agreement, HII reserves the right to immediately and without notice
          terminate or suspend your access to the Service.
        </p>

        <p>
          <b>PROPRIETARY RIGHTS</b>
        </p>
        <p sx={insetStyle}>
          All content and materials comprising the Service, including without limitation, software,
          computer code, HTML, APIs, design, artwork, graphics, video, animations, sounds, musical
          compositions, audio-visual effects, games, titles, themes, objects, icons, virtual items,
          characters, stories, dialogue, catch phrases, concepts, formats, effects, methods of
          operation and documentation, and the selection and arrangement thereof (“Content”) and the
          trademarks, service marks, trade dress and logos contained therein (“Marks”) are owned by
          HII or its licensors. All other trademarks, service marks, trade names and logos used on
          the Service, with or without attribution, are the property of their respective owners.
        </p>

        <p sx={insetStyle}>
          You acknowledge and agree that the Content and Marks are protected by copyrights,
          trademarks, service marks, patents, trade secrets or other proprietary rights and laws of
          Canada and similar laws of other jurisdictions. HII reserves all rights, including without
          limitation, all intellectual property rights or other proprietary rights, in connection
          with the Content and Marks.
        </p>

        <p sx={insetStyle}>
          You will not obtain any ownership interest in the Content, Marks or our Services through
          this Agreement or otherwise. All rights to the Content not expressly granted in this
          Agreement are reserved to their respective copyright owners. Except as expressly
          authorized by this Agreement or on our Services, you may not copy, reproduce, distribute,
          republish, download, perform, display, post, transmit, exploit, create derivative works or
          otherwise use any of the Content or Marks in any form or by any means, without the prior
          written authorization of HII or the respective copyright owner, which may be granted at
          its sole discretion.
        </p>

        <p>
          <b>USER GENERATED CONTENT</b>
        </p>
        <p sx={insetStyle}>
          User generated content (“UGC”) is defined as any content, material, data and information
          that you create, upload to, or transmit through the Service, or that is extracted from
          your Third Party Service profile, including, without limitation, text, images, stories,
          photos, sound, music, drawings, videos and game questions.
        </p>

        <p sx={insetStyle}>
          By posting any UGC on, through or in connection with the Service, you hereby grant to HII
          a perpetual, irrevocable, non-exclusive, fully-paid and royalty-free, sublicensable,
          transferable, worldwide license to use, modify, excerpt, adapt, store, translate,
          sub-license, create derivative works and compilations based upon, publicly perform,
          publicly display, reproduce, and distribute such UGC, in whole or in part, on, through or
          in connection with the Service or in connection with any commercialization, distribution,
          or syndication thereof on and through all media formats now known or hereafter devised,
          for any and all purposes including, but not limited to, promotional, marketing, research,
          trade or commercial purposes. HII’s use of such UGC shall not require any further notice
          to you and such use shall be without the requirement of any permission from or payment to
          you or to any other person or entity. HII may remove any UGC at its sole discretion at any
          time. UGC is not confidential and will not be returned to you.
        </p>

        <p sx={insetStyle}>
          If UGC that you create and publish reproduces your name, likeness, image, and/or voice
          (collectively, “Image”), you acknowledge and agree that HII has the right to use your
          Image as part of the Service pursuant to the broad license stated above. If the UGC you
          publish on the Service features the Image of any person other than yourself, you represent
          and warrant that you have received permission from the relevant person for the use of
          his/her Image by HII in accordance with the terms of this Agreement, and you indemnify HII
          for any losses which HII may incur pursuant to the Indemnification provision of this
          Agreement.
        </p>

        <p sx={insetStyle}>
          Please note that not all Services allow the posting of UGC and HII reserves the right to
          limit the storage capacity of UGC that you post on, through or in connection with the
          Service. HII also reserves the right (but not the obligation) to remove and permanently
          delete any UGC from the Service with or without notice for any reason.
        </p>

        <p sx={insetStyle}>
          You represent and warrant that: (a) you own the UGC posted by you on, through or in
          connection with the Service, or otherwise have the right to grant the license set forth in
          this Section, and (b) the posting of UGC by you on, through or in connection with the
          Service does not violate the privacy rights, publicity rights, intellectual property
          rights, contract rights or any other rights of any person or entity. You agree to pay for
          all royalties, fees, and any other monies owing any person or entity by reason of the use
          of any UGC posted by you on or through the Service.
        </p>

        <p sx={insetStyle}>
          You agree not to share, post, or otherwise disseminate any UGC that is, in HII’s sole
          discretion, offensive, demeaning, defamatory, or otherwise inappropriate, including but
          not limited to: content that directly or indirectly threatens an individual or group,
          promotes self-Injury or suicide, is bullying or harassing, facilitates criminal or
          otherwise illegal activity, promotes sexual violence or exploitation, or is hate speech.
        </p>

        <p sx={insetStyle}>
          If you feel that any UGC violates these terms, you may request that we review it by
          submitting the content for review via email to {emailAddress}.
        </p>

        <p>
          <b>LIVE STREAMING HII GAMES</b>
        </p>
        <p sx={insetStyle}>
          HII welcomes the streaming of its games to a live audience (“Live Streaming Content”) via
          Twitch and other video streaming services, provided that such Live Streaming Content
          complies with this Agreement and the following conditions:
        </p>

        <p sx={insetStyle}>
          Live Streaming Content must have no commercial (i.e., monetary) objective. As an exception
          to this, you are permitted to monetize Live Streaming Content (such as Twitch and YouTube
          videos) with advertisements, subscriptions, bits, or other monetization tools integrated
          into the streaming platform, as well as external subscriptions such as patreon donations.
          You may not sell tickets or charge money for “virtual gameshow nights” and similar
          activities. You may not sell or resell access to the Services.
        </p>
        <p sx={insetStyle}>
          All Live Streaming Content must be appropriate for the audience of the Services, comply
          with applicable law, and be free of any material that violates the rights of others or
          that is offensive, sexually explicit, or defamatory (as determined by HII).
        </p>
        <p sx={insetStyle}>
          You may not say, publish or engage in any conduct that would lead viewers to believe that
          the Live Streaming Content is endorsed, approved or sponsored by HII, or produced in
          partnership with HII. In addition, the Live Streaming Content must not include any
          co-branded content without HII’s express written consent (e.g., promoting sponsorships
          with other companies).
        </p>
        <p sx={insetStyle}>
          You may not use any HII Content or Marks in a manner that is derogatory or that may damage
          the reputation or goodwill of HII (as determined by HII).
        </p>
        <p sx={insetStyle}>
          The Live Streaming Content may not include links or information related to exploits or
          hacks to the Services or any other games.
        </p>
        <p sx={insetStyle}>
          HII, at any time and in its sole discretion, may revoke your permission to create and
          publish Live Streaming Content and reserves the right to remove the content from any
          third-party service and/or take other action it deems appropriate.
        </p>

        <p>
          <b>DMCA POLICY</b>
        </p>
        <p sx={insetStyle}>
          HII respects the intellectual property rights of others and expects users of the Service
          to do the same. We will respond to notices of alleged copyright infringement that comply
          with applicable law and are properly provided to us.
        </p>

        <p sx={insetStyle}>
          If you believe that your content has been copied in a way that constitutes copyright
          infringement, please provide us with the following information: (i) a physical or
          electronic signature of the copyright owner or a person authorized to act on their behalf;
          (ii) identification of the copyrighted work claimed to have been infringed; (iii)
          identification of the material that is claimed to be infringing or to be the subject of
          infringing activity and that is to be removed or access to which is to be disabled, and
          information reasonably sufficient to permit us to locate the material; (iv) your contact
          information, including your address, telephone number, and an email address; (v) a
          statement by you that you have a good faith belief that use of the material in the manner
          complained of is not authorized by the copyright owner, its agent, or the law; and (vi) a
          statement that the information in the notification is accurate, and, under penalty of
          perjury, that you are authorized to act on behalf of the copyright owner.
        </p>

        <p sx={insetStyle}>
          We reserve the right to remove content alleged to be infringing without prior notice and
          at our sole discretion. In appropriate circumstances, HII Games will also terminate a
          user’s account if the user is determined to be a repeat infringer.
        </p>

        <p>
          <b>RULES OF CONDUCT</b>
        </p>
        <p sx={insetStyle}>
          Your use of the Service is subject to all applicable local, state and national laws and
          regulations. You are solely responsible for all activities, acts and omissions that occur
          in, from, through or under your account. You shall not use, allow, or enable others to use
          the Service, in any manner that is, attempts to, or is likely to:
        </p>

        <p sx={insetStyle}>
          be libelous, defamatory, vulgar, indecent, or obscene, pornographic, sexually explicit,
          racially, culturally, or ethnically offensive, harmful, harassing, intimidating,
          threatening, discriminatory, or abusive, or which may or may appear to impersonate anyone
          else;
        </p>
        <p sx={insetStyle}>
          affect us adversely or reflect negatively on us, our goodwill, name or reputation or cause
          duress, distress or discomfort to us or anyone else, or discourage any person, firm or
          enterprise from using all or any portion, features or functions of the Service, or from
          advertising, linking or becoming a supplier to us in connection with the Service;
        </p>
        <p sx={insetStyle}>
          use cheats, exploits, automation software, bots, hacks, mods or any unauthorized third
          party software designed to modify or interfere with the Service or any HII game
          experience;
        </p>
        <p sx={insetStyle}>
          exploit, distribute or publicly inform other members of any game error, miscue or bug
          which gives an unintended advantage;
        </p>
        <p sx={insetStyle}>
          send or result in the transmission of junk e-mail, chain letters, duplicative or
          unsolicited messages, surveys, contests, pyramid scheme, or so-called “spamming” and
          “phishing”;
        </p>
        <p sx={insetStyle}>
          unless approved by us, be used for commercial or business purposes, including, without
          limitation, advertising, marketing or offering goods or services, whether or not for
          financial or any other form of compensation or through linking with any other website or
          web pages;
        </p>
        <p sx={insetStyle}>
          transmit, distribute or upload programs or material that contain malicious code, such as
          viruses, timebombs, cancelbots, worms, trojan horses, spyware, or other potentially
          harmful programs or other material or information;
        </p>
        <p sx={insetStyle}>
          create a false identity for the purpose of misleading others or forge any TCP/IP packet
          header or part of the header information in any email or newsgroup posting for any reason;
        </p>
        <p sx={insetStyle}>
          contact anyone who has asked not to be contacted; “stalk” or otherwise harass anyone;
        </p>
        <p sx={insetStyle}>
          violate any laws, regulations (including, without limitation, laws regarding the
          transmission of technical data or software exported from Canada), judicial or governmental
          order or any treaties, or violate or infringe upon any intellectual property rights,
          rights of publicity or privacy or any other rights of ours or of any other person, firm or
          enterprise;
        </p>
        <p sx={insetStyle}>
          gain unauthorized access to the Service, other users’ accounts, names, passwords,
          personally identifiable information or other computers, websites or pages, connected or
          linked to the Service;
        </p>
        <p sx={insetStyle}>
          disrupt, impair, alter or interfere with the use, features, functions, operation or
          maintenance of the Service or the rights of use and enjoyment of the Service by any other
          person, firm or enterprise; or
        </p>
        <p sx={insetStyle}>
          use the Service in any manner which violates or is inconsistent with the terms and
          conditions of this Agreement.
        </p>

        <p>
          <b>USER DISPUTES</b>
        </p>
        <p sx={insetStyle}>
          You are solely responsible for your interaction with other users of the Service and other
          parties that you come in contact with through the Service. HII hereby disclaims any and
          all liability to you or any third party relating to your use of the Service. HII reserves
          the right, but has no obligation, to manage disputes between you and other users of the
          Service.
        </p>

        <p>
          <b>USER FEEDBACK</b>
        </p>
        <p sx={insetStyle}>
          In general, HII does not accept, via the Service or otherwise, unsolicited submissions
          including, without limitation, submissions of game ideas, characters, drawings,
          information, suggestions, proposals, ideas or concepts (collectively, “User Feedback”).
          Therefore, any similarity between an unsolicited submission and any elements in a HII
          creative work or Service would be purely coincidental. However, on occasion, we may
          request feedback from our users in the form of a survey or similar submission. Any and all
          User Feedback provided by you to HII, whether solicited or not, and all rights contained
          therein, will become the sole property of HII and may be used, copied, sublicensed,
          adapted, transmitted, distributed, publicly performed, published, displayed or deleted as
          HII sees fit. You agree that you are not entitled to any compensation, credit or notice
          whatsoever in connection with your User Feedback or HII’s use thereof.
        </p>

        <p>
          <b>LINKS AND THIRD PARTY</b>
        </p>
        <p sx={insetStyle}>
          The Service may contain links to third-party websites or resources. You acknowledge and
          agree that we are not responsible or liable for: (i) the availability or accuracy of such
          websites or resources; or (ii) the content, products, or services on or available from
          such websites or resources. Links to such websites or resources do not imply any
          endorsement by HII of such websites or resources or the content, products, or services
          available from such websites or resources. You acknowledge sole responsibility for and
          assume all risk arising from your use of any such websites or resources.
        </p>

        <p sx={insetStyle}>
          From time to time, during your use of the Service, you may communicate with, receive
          communications from, be re-directed to, interact with, or participate in or use the
          services or obtain goods and services of or from, third parties such as our advertisers,
          sponsors, promotional partners (collectively, the “Advertisers”). All such communication,
          interaction and participation is between you and such Advertisers and we shall not be
          responsible or liable to you in any way in connection with these activities or
          transactions (including, without limitation, any representations, warranties, covenants,
          contracts or other terms or conditions that may exist between you and the Advertiser or
          any goods or services you may purchase or obtain from any Advertiser).
        </p>

        <p>
          <b>DISCLAIMER OF WARRANTY</b>
        </p>
        <p sx={insetStyle}>
          Your access to and use of the Services or any Content is at your own risk. You understand
          and agree that the Service is provided to you on an “AS IS” and “AS AVAILABLE” basis.
          Without limiting the foregoing, HII AND ITS PARTNERS DISCLAIM ANY WARRANTIES, EXPRESS OR
          IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. We
          make no warranty and disclaim all responsibility and liability for the completeness,
          accuracy, availability, timeliness, security or reliability of the Service or any content
          thereon. HII will not be responsible or liable for any harm to your computer system, loss
          of data, or other harm that results from your access to or use of the Service, or any
          Content. You also agree that HII has no responsibility or liability for the deletion of,
          or the failure to store or to transmit, any Content and other communications maintained by
          the Service. We make no warranty that the Service will meet your requirements or be
          available on an uninterrupted, secure, or error-free basis. No advice or information,
          whether oral or written, obtained from HII or through the Service, will create any
          warranty not expressly made herein.
        </p>

        <p sx={insetStyle}>
          You also acknowledge that the time that you spend on the Service, including any dollar
          amounts or achievements accumulated in our games, is solely for entertainment purposes and
          that no value can be attributed to such time. Accordingly, please do not contact us
          requesting payment of your “winnings” as such requests will not be honored.
        </p>

        <p>
          <b>LIMITATION OF LIABILITY</b>
        </p>
        <p sx={insetStyle}>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, HII AND ITS SUBSIDIARIES, AFFILIATES,
          OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS WILL NOT BE LIABLE FOR ANY DIRECT,
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT
          LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING
          FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE; (ii) ANY
          CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICE, INCLUDING WITHOUT LIMITATION, ANY
          DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY
          CONTENT OBTAINED FROM THE SERVICE; AND (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
          TRANSMISSIONS OR UGC, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
          ANY OTHER LEGAL THEORY, WHETHER OR NOT HII HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH
          DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
          PURPOSE.
        </p>

        <p>
          <b>INDEMNIFICATION</b>
        </p>
        <p sx={insetStyle}>
          Upon HII’s and/or its licensors’ request, you agree to defend, indemnify and hold harmless
          HII, its licensors and any HII affiliates, contractors, vendors, and content providers
          from all liabilities, claims and expenses, including attorneys’ fees, that arise from or
          relate to a breach of these Agreement for which you are responsible or in connection with
          your distribution of any UGC on or through the Service. Without limiting the generality of
          the foregoing, you agree to indemnify and hold HII and its licensors harmless for any
          improper or illegal use of your account, including the illegal or improper use of your
          account by someone to whom you have given permission to use your account. You agree that
          you will be personally responsible for your use of Service and for all of your
          communication and activity on the Service, including any content you contribute, and that
          you will indemnify and hold harmless HII, HII’s licensors and HII affiliates from any
          liability or damages arising from your conduct on the Service, including any UGC that you
          contribute.
        </p>

        <p sx={insetStyle}>
          HII and its licensors reserve the right, at their own expense, to assume the exclusive
          defense and control of any matter otherwise subject to indemnification by you. In that
          event, you shall have no further obligation to provide indemnification to HII and/or its
          licensors in that matter.
        </p>

        <p>
          <b>EXPORT CONTROLS</b>
        </p>
        <p sx={insetStyle}>
          Software available in connection with the Services (the “Software”) is further subject to
          Canada export controls. No Software may be downloaded from the Services or otherwise
          exported or re-exported in violation of Canadian export laws. Downloading or using the
          Software is at your sole risk.
        </p>

        <p>
          <b>CHANGES TO THIS AGREEMENT.</b>
        </p>
        <p sx={insetStyle}>
          HII reserves the right, in its sole discretion, to modify or revise this Agreement at any
          time, and you agree to be bound by such modifications or revisions. Any such change or
          modification will be effective immediately or, where required by law, 30 days after its
          publication in connection with the Service. HII may notify you of any such changes by
          providing a link to the new Agreement before you use the Service, posting the notice of
          such changes on HII’s website, or sending notice via other means. Your continued use of
          the Service after any changes or modifications to this Agreement are posted will
          constitute your acceptance of, and agreement to, such changes or modifications. If you
          object to any change or modification, your sole recourse shall be to cease using the
          Service.
        </p>

        <p>
          <b>GENERAL</b>
        </p>
        <p sx={insetStyle}>
          (a) This Agreement states your entire agreement with HII regarding the use of the Service.
          If any portion of this Agreement is held invalid or unenforceable, that portion shall be
          construed in accordance with applicable law as nearly as possible to reflect the original
          intention of the parties, and the remainder of this Agreement shall remain in full force
          and effect.
        </p>

        <p sx={insetStyle}>
          (b) No delay or failure to take action under this Agreement shall constitute any waiver by
          HII of any provision of this Agreement.
        </p>

        <p sx={insetStyle}>
          (c) This Agreement shall be governed by the laws of the State of Illinois without regard
          to its conflict of laws rules. You expressly agree that exclusive jurisdiction for any
          claim or dispute with HII or affiliates or relating in any way to your use of the Service
          resides exclusively in the courts of Illinois, and you further consent and agree to
          personal jurisdiction by the state and federal courts sitting in the State of Illinois,
          Cook County, in connection with any such dispute.
        </p>

        <p sx={insetStyle}>
          (d) Any cause of action by you must be instituted within one year after the claim or cause
          of action has arisen, or be barred.
        </p>

        <p sx={insetStyle}>
          (e) This Agreement, and any rights and licenses granted hereunder, may not be transferred
          or assigned by you, but may be assigned by HII without restriction.
        </p>

        <p>
          <b>CONTACTING US.</b>
        </p>
        <p sx={insetStyle}>
          If you have any questions about this Agreement, or the practices of HII Games’s Service,
          please contact us at {emailAddress}.
        </p>
      </div>
    </PageWrapper>
  );
};
