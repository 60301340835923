import { ActionsObservable, combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

import { CREATE, GET_CONNECTION, JOIN, INTERACT, CONFIGURE } from "./lobbyActions";
import { sendAction } from "../connection/connectionActions";

export const connectionEpic = (action$: ActionsObservable<any>): Observable<any> =>
  action$.ofType(GET_CONNECTION).pipe(switchMap(() => sendAction({ action: "info", data: {} })));

export const createEpic = (action$: ActionsObservable<any>): Observable<any> =>
  action$
    .ofType(CREATE)
    .pipe(
      switchMap(({ payload: { playerName } }) =>
        sendAction({ action: "createroom", data: { playerName } })
      )
    );

export const joinEpic = (action$: ActionsObservable<any>): Observable<any> =>
  action$
    .ofType(JOIN)
    .pipe(
      switchMap(({ payload: { playerName, roomKey } }) =>
        sendAction({ action: "joinroom", data: { playerName, roomKey } })
      )
    );

export const interactEpic = (action$: ActionsObservable<any>): Observable<any> =>
  action$
    .ofType(INTERACT)
    .pipe(switchMap(({ payload: data }) => sendAction({ action: "interact", data })));

export const configureEpic = (action$: ActionsObservable<any>): Observable<any> =>
  action$
    .ofType(CONFIGURE)
    .pipe(switchMap(({ payload: data }) => sendAction({ action: "configure", data })));

export default combineEpics(connectionEpic, createEpic, joinEpic, interactEpic, configureEpic);
