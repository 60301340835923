/** @jsxImportSource theme-ui */

import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { LogOnMount } from "@amplitude/react-amplitude";

import { Button } from "../../components/Button";
import { FormRow } from "../../components/FormRow";
import { TextField } from "../../components/TextField";
import { AuthWrapper } from "./wrapper";
import { AUTH_PASS } from "../../config";
import { login } from "./utils";

const ConfirmEmailSchema = Yup.object().shape({ code: Yup.number().required() });

export const ConfirmEmail = () => {
  const [error, setError] = useState<string>();
  const [redirect, setRedirect] = useState("");
  const [signupPassword] = useState(localStorage.getItem(AUTH_PASS));
  const { username } = useParams<{ username: string }>();

  return (
    <AuthWrapper>
      {redirect ? (
        <div>
          <LogOnMount
            eventType="account created"
            eventProperties={(props: any) => ({
              ...props,
              username,
            })}
          />
          <Redirect to={{ pathname: redirect }} />
        </div>
      ) : (
        <Formik
          initialValues={{ code: "" }}
          validationSchema={ConfirmEmailSchema}
          onSubmit={(values) => {
            setError("");
            Auth.confirmSignUp(username, values.code)
              .then(() => {
                console.debug("confirmed");
                if (!signupPassword) {
                  setRedirect("/login");
                } else {
                  login(username, signupPassword)
                    .then(() => {
                      localStorage.removeItem(AUTH_PASS);
                      setRedirect("/");
                    })
                    .catch(() => setRedirect("/login"));
                }
              })
              .catch((err) => {
                console.error("this error", err);
                setError(err.message);
              });
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <LogOnMount
                eventType="confirmationPage"
                eventProperties={(props: any) => ({
                  ...props,
                  username,
                })}
              />
              <FormRow>
                <TextField
                  name="code"
                  label="Confirmation Code"
                  required
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.code}
                />
              </FormRow>

              <Button
                sx={{ margin: 3, fontSize: 3, backgroundColor: "text" }}
                type="submit"
                label="Confirm Email"
              />

              {error && (
                <p
                  sx={{
                    color: "error",
                    fontSize: [1, 2, 2],
                  }}
                >
                  {error}
                </p>
              )}
            </form>
          )}
        </Formik>
      )}
    </AuthWrapper>
  );
};
